import { Component, EventEmitter, Input, NgZone, OnInit, Output,inject } from '@angular/core';
import { AuthService } from '../auth.service';
import { CollectionsService } from '../collections.service';
import { EngineService } from '../engine.service';
import { Result, loadAdvancedSearchQueryActions, loadFacetSetActions, loadQueryActions, loadSearchActions, loadSearchAnalyticsActions } from '@coveo/headless';
import { CONFIG } from 'src/environments/environment.development';
import { SharedService } from '../shared.service'
import { Router } from '@angular/router';
import { CookieServiceWrapper } from '../cookie-service-wrapper.service';
import { CryptoService } from '../crypto.service';
import {DOCUMENT} from '@angular/common';
import { Customeventlogging } from '../customevent-logging.service';
import { ENVIRONMENT,SYSTEM_MSGS } from 'src/environments/environment.development';


@Component({
  selector: 'app-collections',
  templateUrl: './collections.component.html',
  styleUrls: ['./collections.component.css']
})
export class CollectionsComponent implements OnInit{
  vvmcCollections:any;
  vvqdCollections:any;
  noCollectionsAvailableText:any = CONFIG.COLLECTIONS.NO_COLLECTIONS_AVAILABLE;
  addOrRemoveFromCollectionsTitle:any = CONFIG.COLLECTIONS.ADD_REMOVE_COLLECTIONS_TITLE
  addOrRemoveFromCollectionsMsg1:any = CONFIG.COLLECTIONS.ADD_REMOVE_COLLECTIONS_INFO_MESSAGE1;
  addOrRemoveFromCollectionsMsg2:any = CONFIG.COLLECTIONS.ADD_REMOVE_COLLECTIONS_INFO_MESSAGE2;
  private dom:any = inject(DOCUMENT);
  spCollections:any;
  @Output() close = new EventEmitter<void>();
  @Input() selectedDocs: any
  @Input() result!: Result;
  @Input() oneDriveDocs: any;
  // collection_icon_unfilled = CONFIG.COLLECTIONS.UNFILLED_ICON
  // collection_icon_filled = CONFIG.COLLECTIONS.FILLED_ICON
  @Input() buttons!: string[];
  @Input() document!: any;
  @Input() onedrive_icon!: any;
  collectionViewFlag : boolean = false;
  collectionAddFlag  : boolean =true;
  @Input() closeBtn!: any;
  @Input() allCollections:any;
  @Input() loadCollectionsPartial:any;
  userId!:string;
  @Output("mycollections") mycollections: EventEmitter<any> = new EventEmitter();
  noRecordsMeg = "";
  @Input() sortOrder: any;

  constructor(
    private Auth:AuthService, 
    private collectionsService:CollectionsService, 
    private sharedService : SharedService,
    private engineService:EngineService,
    private ngZone: NgZone,
    private router:Router,
    private cookieService:CookieServiceWrapper,
    private cryptoService:CryptoService,
    private eventLoggingService : Customeventlogging
    ){

      // this.loadAllCollections();
  }

  mainCollectionItemFlag : boolean = false;

  ngOnInit(): void {
  //   let selectedDocsCount:any = localStorage.getItem("selectedDocuments");
  //   if(selectedDocsCount != null){
  //  // selectedDocsCount = JSON.parse(selectedDocsCount);
  //   if(selectedDocsCount.length == 0){
  //          if(this.dom.getElementById("showSelectedFlag")){
  //            this.dom.getElementById("showSelectedFlag").style.display = "none";
  //          }
  //   }
  //  }
    this.userId = this.Auth.getUserId();
    // this.sharedService.loadCollectionsPartial$
    //  this.loadAllCollections();
    this.sharedService.loadCollectionsPartial$.subscribe(value => {
      let collection = value;
      // this.loadSpecificCollection(collection,'')
   });
  }

  // public loadSpecificCollection(collection : any, tab:any) { 
  //   this.successFlag = true;
  //   this.mycollections.emit();
  // }


  collectionRecordsCount=0
  currentCollection:any;
  public loadSpecificCollection(collection : any, tab:any) { 
    this.cookieService.set("ucon","", 1, '/', ENVIRONMENT.DOMAIN.HOST,true,'Strict');

   this.updateCollectionsUsed(collection.id);

    this.clearAll();
    if(this.dom.getElementById("bannerCollections")){
      this.dom.getElementById("bannerCollections").style.display= "";
     }
    this.wait(1000);
    this.currentCollection = collection;
    // console.log('collection : ' + JSON.stringify(collection));
    var collectionData = {
      'collection':collection,
    };
    this.sharedService.sendCollectionData(collectionData);

    var ids="("
    this.collectionRecordsCount = collection.items.length;
    this.mycollections.emit(this.collectionRecordsCount);
    if(collection.items.length>0){
      
      for (var item of collection.items) {
        // console.log(item.docId); // prints values: 10, 20, 30, 40
        // ids.push(item.docId)
        ids = ids + item.docId +",";
      }
      ids = ids.slice(0,-1);
      // this.mycollections.emit();
    }else{
      // alert("There are no record in your collection.");
      return;
    }
    ids=ids+")";
    // console.log("ids-->"+ids);
    const engine = this.engineService.getEngibeObj();
        const action = loadAdvancedSearchQueryActions(engine).updateAdvancedSearchQueries({
          aq: '(@contentsourceid=='+ids+') OR (@spouniqueid=='+ids+')',
          // aq: '(@contentsourceid=(996,3670,3700,3808,4064,7377257b-36f8-4ff2-a3d7-c3929b7385bd,316f6908-a2cd-4320-827b-b2e05c8b6f6b) OR @spouniqueid=(996,3670,3700,3808,4064,7377257b-36f8-4ff2-a3d7-c3929b7385bd,316f6908-a2cd-4320-827b-b2e05c8b6f6b))',
        });
        
        const { executeSearch } = loadSearchActions(engine);
        const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
        engine.dispatch(action);
        engine.dispatch(executeSearch(logSearchboxSubmit()));
  }

  selctedDocument:any =[];
  collectionslist : any = [];





  selectedCollections: any =[];
  removedCollections:any=[];
  selectedID : any;
  onCollectionCheckboxChange(e,list){
    this.selectedID = list.id;
    if(e.target.checked){
      var enteredInRemovedCollections = false;
      this.removedCollections.forEach((value,index)=>{
        if(value==e.target.value){
          enteredInRemovedCollections = true;
          this.removedCollections.splice(index,1);
        } 
      })
      if(!enteredInRemovedCollections){
        this.selectedCollections.push(e.target.value);
      }
    }else{
      var enteredInSelectedCollections = false;
      this.selectedCollections.forEach((value,index)=>{
        if(value==e.target.value){
          enteredInSelectedCollections = true;
          this.selectedCollections.splice(index,1);
        } 
      })
      if(!enteredInSelectedCollections){
        this.removedCollections.push(e.target.value);
      }
    }
    // console.log(JSON.stringify(this.selectedCollections));
    // console.log(JSON.stringify(this.removedCollections));
  }

  maxLength =CONFIG.SAVED_SEARCHES.MAX_LENGTH;
  description:any= "";
  color:any= "black";
  descriptionValidationMessage:any ="";
  onInputChange(event:any){
        // console.log(this.description.length); 
       
        // console.log(event.target);
            if(this.description.length ==this.maxLength){
                 this.color = "red";
             // this.descriptionValidationMessage = `Character Limit Exceeded. Maximum Characters Allowed: ${this.maxLength}`
             this.descriptionValidationMessage = `You may only enter up to ${this.maxLength} characters. Maximum number of characters reached.`
            }
            else{
              this.descriptionValidationMessage = ` ${this.maxLength - this.description.length}  characters remaining`;
              this.color= "black";
            }
                
  }
  
  addToCollectionFlag : boolean = false;
  removeCollectionFlag : boolean = false;
 
  sourceFlag : String = "";
  selectedDocumentDetails : any;
  // @Input() successFlag : boolean = false;
  successFlag : boolean = false;
  failedFlag : boolean = false;
  addTocollections(result,flag){
    // console.log("addTocollections called");
    this.document = result;
    // GMSA News
    // console.log("source", this.document); 
    // if(this.document.raw.source == "Veeva Vault QualityDocs Source-1"){
    if(result.raw.source == "Veeva Vault MedComms" || result.raw.source =="Idea Exchange"){
      this.sourceFlag = "VVMC";
      this.selctedDocument.push({
        docId : result.raw.contentsourceid,
        docNumber : result.raw.contentdocumentnumber
      })
  
    }
    else if(result.raw.source =="Value Evidence Environmental Scan"){
      this.sourceFlag = "VEES";
      this.selctedDocument.push({
        docId : result.raw.contentsourceid,
        docNumber : result.raw.contentsourceid
      })
    }
    else if(result.raw.source == "GMSA News"){
      this.sourceFlag = "SP";
      this.selctedDocument.push({
        docId : result.raw.spouniqueid,
        docNumber : result.raw.spouniqueid,
      })
  
    }else if(result.raw.source == "Veeva Vault PromoMats"){
      this.sourceFlag = "VVPM";
      this.selctedDocument.push({
        docId : result.raw.contentsourceid,
        docNumber : result.raw.contentdocumentnumber,
      })
  
    }
    else{
      this.sourceFlag = "VVQD";
      this.selctedDocument.push({
        docId : result.raw.contentsourceid,
        docNumber : result.raw.contentdocumentnumber
      })
      // console.log("selctedDocument", this.selctedDocument);
    }
    // console.log("sourceFlag", this.sourceFlag);
    this.loadAllCollections();
    if(flag == "add"){
      // console.log("result", result);
      this.selectedDocumentDetails = result;
      this.addToCollectionFlag = true;
    }
    else{
      // console.log("result", result);
      this.removeCollectionFlag = true;
    }


  
  }

  checkCollectionSize(collectionslist){
    if(collectionslist.length>0 && this.selectedCollections.length>0){
      return false;
    }else{
      return true;
    }
  }

  async saveCollectionChangesMultiple(x){

    this.ngZone.run(async () => {

   // this.disableElement("saveCollectionChangesId");
   this.disableElement(x);
    // console.log("this.selecterddocus", this.selectedDocs);
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Add/Remove-Collections-Multi-Actual',Details : this.selectedDocs},'Add/Remove-Collections-Multi-Actual');

    // console.log("this.selectedCollections--->",this.selectedCollections);
    // console.log("this.removedCollections--->"+JSON.stringify(this.removedCollections));
    // console.log("this.document.raw.contentsourceid--->"+JSON.stringify(this.document.raw.contentsourceid));
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Click',Details : this.selectedDocs},'MyCollections-Multi');
    // this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Click',Details : this.selectedDocs},'MyCollections-Multi');


    let res:any = [];

   for(let j = 0; j < this.selectedDocs.length;j++){
    for(let i = 0 ;i < this.selectedCollections.length ; i++)
    {
      var items:any = [];
      var item={
        docId:this.selectedDocs[j].docId,
        docNumber:""
      }
      items.push(item);
      var payload:any = {
        "name": "undefined",
        "id": this.selectedCollections[i],
        "items": items,
        "userId": this.userId,
        "operation": "UPDATE_DOC",
        "feature": this.sourceFlag,
        "description": this.description,
        "isCollectionNameChanged": false
    }
      let response: any = await (this.collectionsService.addDocToCollection(payload)).toPromise();
      // console.log("response", response);

      res.push(response);

      // if(response.isActive == 1){
      //   this.renameFlag = false;
      //   this.loadAllCollections();
      //   this.addToCollectionFlag = false;
      //   this.successFlag = true;
      // }
  
    }

    var failCount = 0;
    for(let z=0; z<res.length; z++){
      if(res[z].isActive == 1){

      }else{
        failCount = failCount+1;
      }
    }
    if(failCount==0){
      this.renameFlag = false;
        this.loadAllCollections();
        this.addToCollectionFlag = false;
        this.successFlag = true;
        //this.message = "Successfully added or removed document(s)/link(s) to the Collections."
        this.message = CONFIG.COLLECTIONS.SUCCESSFUL_MESSAGE;
    }else{
      this.renameFlag = false;
        this.loadAllCollections();
        this.addToCollectionFlag = false;
        this.successFlag = true;
        //this.message = "Successfully added or removed document(s)/link(s) to the Collections."
        this.message = CONFIG.COLLECTIONS.SUCCESSFUL_MESSAGE;
    }

   }







   for(let j = 0; j < this.selectedDocs.length;j++){
    for(let i = 0 ;i < this.removedCollections.length ; i++)
    {
      var items:any = [];
      items.push(this.selectedDocs[j].docId);
      var payload:any = {
        "name": "undefined",
        "id": this.removedCollections[i],
        "items": items,
        "userId": this.userId,
        "operation": "DELETE_DOC",
        "feature": this.sourceFlag,
        "description": this.description,
        "isCollectionNameChanged": false
    }
      let response: any = await (this.collectionsService.addDocToCollection(payload)).toPromise();
      // console.log("response", response);
      if(response.isActive == 1){
        this.renameFlag = false;
        this.loadAllCollections();
        this.addToCollectionFlag = false;
        this.removeCollectionFlag = false;
        this.successFlag = true;
      }
  
    }
   }

  //  this.message = "Successfully added or removed document(s)/link(s) to the Collections.";
  this.message = CONFIG.COLLECTIONS.SUCCESSFUL_MESSAGE;
   // console.log("done");
   localStorage.setItem("selectedDocuments","");
   this.selectedDocs = [];
   localStorage.removeItem("selectedDocsDisabilityCheck");
    localStorage.removeItem("isOneDriveInProgress");
   if(this.dom.getElementById("showSelected")){
    this.dom.getElementById("showSelected").style.display="none";
   }
  this.enableElement(x);
    // }
  })
    // this.sharedService.sendCollectionUpdate(this.message);
   // this.enableElement("saveCollectionChangesId");

  }
  
  async saveCollectionChanges(x:any){
    //this.disableElement("saveCollectionChangesId");
  if(this.selectedDocs.length == 0){
   this.saveCollectionChangesSingle(x);
  }
  else
  {
    this.saveCollectionChangesMultiple(x);

  }
  //this.enableElement("saveCollectionChangesId");
  }

  refreshThePageList(){
    //this.cookieService.set('selectedDocuments',	"");
   localStorage.setItem('selectedDocuments',	"");
    const engine = this.engineService.getEngibeObj();
    const { updateQuery } = loadQueryActions(engine);
    const { executeSearch } = loadSearchActions(engine);
    const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
    engine.dispatch(executeSearch(logSearchboxSubmit()));
  }

  async saveCollectionChangesSingle(x){
    this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Add/Remove-Collections-Actual',Details : this.selectedDocs},'Add/Remove-Collections-Actual');

    this.removeCollectionFlag = false;
     this.disableElement(x);
    // console.log("this.selecterddocus", this.selectedDocs);
    // console.log("this.selectedCollections--->",this.selectedCollections);
    // console.log("this.removedCollections--->"+JSON.stringify(this.removedCollections));
    // console.log("this.document.raw.contentsourceid--->"+JSON.stringify(this.document.raw.contentsourceid));

    //adding docs to collections
    for(let i = 0 ;i < this.selectedCollections.length ; i++)
    {
      var payload = {
        "name": "undefined",
        "id": this.selectedCollections[i],
        "items": this.selctedDocument,
        "userId": this.userId,
        "operation": "UPDATE_DOC",
        "feature": this.sourceFlag, 
         "description":"", 
         "isCollectionNameChanged": false
    }
      let response: any = await (this.collectionsService.addDocToCollection(payload)).toPromise();
      // console.log("response", response);
      if(response.isActive == 1){
        this.renameFlag = false;
        this.loadAllCollections();
        this.addToCollectionFlag = false;
        this.successFlag = true;
      }
  
    }


    // removing docs from collections
    for(let i = 0 ;i < this.removedCollections.length ; i++)
    {
      var items : any = [];
      // console.log("this.selctedDocument--->"+JSON.stringify(this.selctedDocument));
      items.push(this.selctedDocument?.[0].docId);
      var payload = {
        "name": "undefined",
        "id": this.removedCollections[i],
        "items": items,
        "userId": this.userId,
        "operation": "DELETE_DOC",
        "feature": this.sourceFlag,
        "description":"", 
         "isCollectionNameChanged": false
    }
      let response: any = await (this.collectionsService.addDocToCollection(payload)).toPromise();
      // console.log("response", response);
      if(response.isActive == 1){
        this.renameFlag = false;
        this.loadAllCollections();
        this.addToCollectionFlag = false;
        this.removeCollectionFlag = false;
        this.successFlag = true;
      }
  
    }
    //this.message = "Successfully added or removed document(s)/link(s) to the Collections."
    this.message = CONFIG.COLLECTIONS.SUCCESSFUL_MESSAGE;
    // this.sharedService.sendCollectionUpdate(this.message);
    this.enableElement(x);
   

  }
  message:any;

  closeRenamePopup(){
  this.renameFlag = false;
  this.CollectionRenamePopupShow = false;
  this.descriptionValidationMessage = "";
  }

  alreadyUsedCollections:any = [];
  isDocPartOfCollection(collection:any){
    // // console.log("collection-->"+JSON.stringify(collection));
    if(this.selectedDocs.length>0){

      var items = collection.items;
      if(items.length>0){
        for(let item of items){
          if(this.document.raw['source'] == "GMSA News"){
            for(var doc of this.selectedDocs){
              if(item.docId ==  doc.docId){
                this.alreadyUsedCollections.push(collection.id);
                // // console.log("this.alreadyUsedCollections-->"+JSON.stringify(this.alreadyUsedCollections));
                return true;
              }
            }
          }else{
            for(var doc of this.selectedDocs){
              if(item.docId ==  doc.docId){
                this.alreadyUsedCollections.push(collection.id);
                // // console.log("this.alreadyUsedCollections-->"+JSON.stringify(this.alreadyUsedCollections));
                return true;
              }
            }
          }
        }
        
      }
      return false;
      
    }else{

    var items = collection.items;
    if(items.length>0){
      for(let item of items){
        if(this.document.raw['source'] == "GMSA News"){
          if(item.docId ==  this.document.raw.spouniqueid){
            this.alreadyUsedCollections.push(collection.id);
            // // console.log("this.alreadyUsedCollections-->"+JSON.stringify(this.alreadyUsedCollections));
            return true;
          }
        }else{
          if(item.docId ==  this.document.raw.contentsourceid){
            this.alreadyUsedCollections.push(collection.id);
            // // console.log("this.alreadyUsedCollections-->"+JSON.stringify(this.alreadyUsedCollections));
            return true;
          }
        }
      }
      
    }
    return false;

    }

    
  }



  closePopup() {
    this.close.emit();
  }

  //Collection Functions

//  shareLink = "https://gmsasearch-sit.merck.com/index.html";
//shareLink = "https://gmsasearch-dev.merck.com/index.html";
shareLink = CONFIG.COLLECTIONS.SHARE_LINK_URL
 
 renameFlag : boolean = false;

 renameValue : any
 renameTextValue(event){
  this.renameValue = event.target.value
  // console.log("value",event.target.value);
 }
 newnameValue : any;
 newnameTextValue(event){
  this.newnameValue = event.target.value
  // console.log("value",event.target.value);
 }

 seletedRenameList :any;
 selecedNameList: any;
async rename(list){

  

  this.renameFlag = true;
  this.seletedRenameList = list;
  
}
disableElement(id){
  if(this.dom.getElementById(id)){
  this.dom.getElementById(id).disabled = true;
  this.dom.getElementById(id).style.cursor="not-allowed";
  //document.getElementById(id).style.backgroundColor="grey";
  this.dom.getElementById(id).style.background="linear-gradient(to right, grey 50%, grey 50%)";
  }
   }

   enableElement(id){
    if(this.dom.getElementById(id)){
    this.dom.getElementById(id).disabled = false;
    //document.getElementById(id).style.cursor="pointer";
    //document.getElementById(id).style.background="linear-gradient(to right, #045F5F 50%,teal 20%)";
    //document.getElementById(id).style.background="linear-gradient(to right, grey 50%, grey 50%)";
    //document.getElementById(id).style.background= "";
    this.dom.getElementById(id).removeAttribute("style");
    }
       }

 renameUpdate(){
   this.disableElement("renameCollectionId");
  let payload = {
    "name": this.renameValue,
    "id": this.seletedRenameList.id,
    "items": [this.seletedRenameList.items],
    "userId": this.userId,
    "operation": "UPDATE_COLLECTION",
    "feature": "VVMC",
    "description":"", 
         "isCollectionNameChanged": false
}
  let response: any = (this.collectionsService.addDocToCollection(payload)).toPromise();
  // console.log("response", response);
  this.renameFlag = false;
  this.loadAllCollections();
  this.enableElement("renameCollectionId");
  // const dataToSend = 'download complete';
  // this.sharedService.sendData(dataToSend);
}
deleteFlag : boolean = false;

 selectionList:any = [];

// mainCheckBox(){
//    var mainCheckBoxValue = this.dom.getElementById("mainCheckBox");
//    console.log(mainCheckBoxValue.checked); 
//    if(mainCheckBoxValue.checked){
//     for(let i=0; i<10; i++){
//        let multiselectValues = this.dom.getElementsByClassName("multiselect");
//        multiselectValues[i].checked = true;
//        this.selectionList.push(this.allCollections[i]);
//     }
//    }
//    else{
//     for(let i=0; i<10; i++){
//       let multiselectValues = this.dom.getElementsByClassName("multiselect");
//       multiselectValues[i].checked = false;
//       this.selectionList = [];
//    }
//    }
  

// }
maxSelectionReached = false;
addRemoveToMultiList(collection){
  console.log("this.selectionList.length", this.selectionList.length);

//   var checkifFirstFewItemsSelected = true;

//   for(let i=0; i<10; i++){
//     let multiselectValues = this.dom.getElementsByClassName("multiselect");
//     if(multiselectValues[i].checked == false){
//       checkifFirstFewItemsSelected = false;
//        break;
//     }
//  }
//  if(checkifFirstFewItemsSelected){
//     this.dom.getElementById("mainCheckBox").checked = true;
//  }
//  else{
//   this.dom.getElementById("mainCheckBox").checked = false;
//  }

  var selectedItem = this.dom.getElementById(collection.id);
   if(this.selectionList.length > 9 &&  selectedItem.checked == true){
    selectedItem.checked = false;
          this.maxSelectionReached = true;
          return;
   }



   if(selectedItem.checked){
    this.selectionList.push(collection);
    console.log("selectionList",this.selectionList);
   }
   else{
         let index =  this.selectionList.indexOf(collection);
         this.selectionList.splice(index,1);
         console.log("selectionList",this.selectionList);
   }
}

closeDeletePopup(){
  this.deleteFlag = false;
}

deleteCollection(){
  this.disableElement("deleteCollectionId");
  let payload = 
  {
    "name": "undefined",
    "id": this.selectedDeleteList.id,
    "items": [],
    "userId": this.userId,
    "operation": "DELETE_COLLECTION",
    "feature": "",
    "description":"", 
         "isCollectionNameChanged": false
}
  let response: any = (this.collectionsService.addDocToCollection(payload)).toPromise();
  // console.log("response", response);
  this.renameFlag = false;
  this.loadAllCollections();
  this.enableElement("deleteCollectionId");
}

@Input() newnameFlag : boolean= false;

selectedDeleteList : any;
delete(list){
  this.selectedDeleteList = list;
  this.selecedNameList = list.name;
  this.deleteFlag =true;
  // console.log("list",list);
}

share(list){
  let userFullName = this.cookieService.get("fn");
 // userFullName = userFullName? this.cryptoService.decryptData(userFullName):"";
  // // console.log("list",this.selectedDocumentDetails);
  var email = "";
  var subject = 'GMSA Content Search Navigator: Asset share';
  var emailBody = ''
   //emailBody +=userFullName+" has invited you to access and explore the information for the document: "+this.selectedDocumentDetails.raw.title+" <br>%0D%0A Link:  "+encodeURIComponent("https://gmsasearch-sit.merck.com/index.html#t="+"VVMC"+"&sort=relevancy&collection="+list.id);
  emailBody +=userFullName+" has invited you to access and explore the information for the document: "+this.selectedDocumentDetails.raw.title+" <br>%0D%0A Link:  "+encodeURIComponent("https://gmsasearch-dev.merck.com/index.html#t="+"VVMC"+"&sort=relevancy&collection="+list.id);
 //emailBody +=userFullName+" has invited you to access and explore the information for the document: "+this.selectedDocumentDetails.raw.title+" <br>%0D%0A Link:  "+encodeURIComponent("https://gmsasearch-dev.merck.com/index.html#t="+"VVMC"+"&sort=relevancy&collection="+list.id);
  // emailBody +=userFullName+" has invited you to access and explore the information for the document: "+this.selectedDocumentDetails.raw.title+" <br>%0D%0A Link:  "+encodeURIComponent("https://gmsasearch-sit.merck.com/index.html#t="+"VVMC"+"&sort=relevancy&collection="+list.id);
 // emailBody +=userFullName+" has invited you to access and explore the information for the document: "+this.selectedDocumentDetails.raw.title+" <br>%0D%0A Link:  "+encodeURIComponent("https://gmsasearch-dev.merck.com/index.html#t="+"VVMC"+"&sort=relevancy&collection="+list.id);
//  emailBody +=userFullName+" has invited you to access and explore the information for the document: "+this.selectedDocumentDetails.raw.title+" <br>%0D%0A Link:  "+encodeURIComponent("https://gmsasearch-dev.merck.com/index.html#t="+"VVMC"+"&sort=relevancy&collection="+list.id);
  emailBody +="%0D%0A %0D%0A"+this.disclaimer_label+"%0D%0A";
  emailBody += this.disclaimer_txt;
  document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
}

saveCollections(){

  let payload = {
    "name": "undefined",
    "id": 469,
    "items": [
        {
            "docId": 4141,
            "docNumber": "MDD-0013"
        }
    ],
    "userId": this.userId,
    "operation": "UPDATE_DOC",
    "feature": "VVMC",
    "description":"", 
         "isCollectionNameChanged": false
}
let response: any = (this.collectionsService.addDocToCollection(payload)).toPromise();
// console.log("response", response);
this.newnameFlag = false;
this.loadAllCollections();
}

shareDocument(){
  // https://gmsasearch-sit.merck.com/index.html#q=%40contentsourceid%3Dcbf9dbe2-d79d-4dab-a04e-0d9128c6a05e&t=SP&sort=relevancy
  // console.log("this.selectedDocumentDetails.Title",this.selectedDocumentDetails);
  var email = "";
  var subject = 'GMSA Content Search Navigator: Asset share';
  var emailBody = ''
  emailBody +="Vikas Guru has invited you to access and explore the information for the document: "+this.selectedDocumentDetails.Title+"%0D%0A Link:  "+encodeURIComponent("https://gmsasearch-dev.merck.com/index.html#q=%40contentsourceid%3D"+this.selectedDocumentDetails.raw.permanentid+"");
  emailBody +="%0D%0A %0D%0A"+this.disclaimer_label+"%0D%0A";
  emailBody += this.disclaimer_txt;
  document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
}

disclaimer_label = "About GSMA Content Search Navigator:"
disclaimer_txt = "The GMSA Content Search Navigator is an advanced search engine tool that provides GMSA colleagues with a simplified approach for efficiently and effectively searching across multiple repositories for scientific and related content.";

addremoveCollections()
{
  this.newnameFlag = true;
}

  @Input() colObj:any;
  async addCollections (){

    
  if(this.colObj){
    // this.collectionName = this.colObj.name;
    // this.disableElement("addCollectionsId");
  this.existnameMessage  ="";

  let payload = 
  {
    "name": this.collectionName,
    "id": "",
    "items": [],
    "userId": [
      this.userId
    ],
    "collectionid": this.colObj.id,
    "statustxt": "shared",
     "description": this.description,
    "feature": "ALL"
   
}

  // const response:any = await (this.collectionsService.createCollection(payload));
  // // console.log("response", response);
  // this.newnameFlag = false;
  this.collectionsService.createCollection(payload).subscribe((result)=>{
    // console.log("resukt", result);
    if(result.status == '1003')
    {
       this.existnameFlag = true;
       this.existnameMessage = result.message;

    }

    //  this.collectionslist= [];
    if(result.collectionName){
      this.sharedService.sendSharedCollectionSave("Saved");
      this.loadAllCollections();
      this.newnameFlag = false;
    }
   })
   this.enableElement("addCollectionsId");



  }  else{

    this.disableElement("addCollectionsId");
  this.existnameMessage  ="";
  let payload = 
  {
    "name": this.newnameValue,
    "id": "",
    "items": [],
    "userId": [
      this.userId
    ],
    "collectionid": "",
    "statustxt": "created",
    "description": this.description,
    "feature": "ALL"
}
  // const response:any = await (this.collectionsService.createCollection(payload));
  // // console.log("response", response);
  // this.newnameFlag = false;
  this.collectionsService.createCollection(payload).subscribe((result)=>{
    // console.log("resukt", result);
    if(result.status == '1003')
    {
       this.existnameFlag = true;
       this.existnameMessage = result.message;

    }

    //  this.collectionslist= [];
    if(result.collectionName == this.newnameValue){
      
      this.loadAllCollections();
      this.newnameFlag = false;
    }
   })
   this.enableElement("addCollectionsId");

  }
  this.description = "";
  this.descriptionValidationMessage = "";

}

existnameFlag : boolean = false;
existnameMessage : String = '';

closeNewCollection(){
  this.newnameFlag = false;
  this.existnameMessage = "";
}


limitedCollections:any = [];
collectionMainFlag : boolean = false;
loadingIndicater = false;
loadAllCollections(){
  this.limitedCollections = [];
  this.loadingIndicater = true;
  this.ngZone.run(() =>{
  var tab = this.sourceFlag;
  if(tab == "GMSA News"){
    tab = "SP"
  }
  else if(tab == "Veeva Vault MedComms"){
    tab = "VVMC"
  }
  // console.log("tab",tab);
  var data = {
        "collectionId": "",
        "userId": this.userId,
        "tab": tab
    }
    this.collectionsService.getAllCollections(data).subscribe((collections)=>{
    this.collectionslist = collections.collectionlist;
    // console.log("collectionslist", this.collectionslist);

    if(this.collectionslist?.length == 0){
       this.noCollectionFlag = true;
       this.mainCollectionItemFlag = true;
       this.noCollectionMessage = "No Collection Available"
    }
    // console.log("noCollectionFlag", this.collectionMainFlag);

    let count = 0
    try{
      for(let i=0; i<this.collectionslist?.length; i++){
        this.limitedCollections.push(this.collectionslist[i]);
        count = count+1;
        if(count>=5){
          break;
        }
      }
    }catch(err){

    }
    this.limitedCollections = new Set(this.limitedCollections);
    this.allCollections = collections.collectionlist;
    // this.allCollections.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
    

    if(this.sortOrder==='ascending'){
      // this.collectionslist.sort((a,b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : ((b.name.toLowerCase() > a.name.toLowerCase()) ? -1 : 0))
      this.collectionslist.sort((a,b) => (a.name.localeCompare(b.name)));
    }else if(this.sortOrder==='descending'){
      // this.collectionslist.sort((a,b) => (b.name.toLowerCase() > a.name.toLowerCase()) ? 1 : ((a.name.toLowerCase() > b.name.toLowerCase()) ? -1 : 0))
      this.collectionslist.sort((a,b) => (b.name.localeCompare(a.name)));
    }else if(this.sortOrder==='date-ascending'){
      this.collectionslist.sort((a,b) => new Date(a.date).getTime() - new Date(b.date).getTime())
    }else if(this.sortOrder==='date-descending'){
      this.collectionslist.sort((a,b) => new Date(b.date).getTime() - new Date(a.date).getTime())
    }else{
      this.allCollections.sort((a,b) => (a.name.localeCompare(b.name)));
    }


    // console.log("this.allCollections------>"+JSON.stringify(this.allCollections));
    // console.log("this.limitedCollections------>"+JSON.stringify(this.limitedCollections));

    if(!this.allCollections ){
      this.noRecordsMeg = CONFIG.COLLECTIONS.NO_RECORDS_AVAILABLE;
    }

    // this.message = "Success."
    //  this.collectionslist= [];
   })
  })

  this.loadingIndicater = false;

}


compare(propName) {
  return function(a,b) {
      if (a[propName] < b[propName])
          return -1;
      if (a[propName] > b[propName])
          return 1;
      return 0;
  };
}



getCollectionsList(){
  var tab = this.sourceFlag;
  if(tab == "GMSA News"){
    tab = "SP"
  }
  else if(tab == "Veeva Vault MedComms"){
    tab = "VVMC"
  }
  // console.log("tab",tab);
  var data = {
        "collectionId": "",
        "userId": this.userId,
        "tab": tab
    }
  let collectionList:any;  
  this.collectionsService.getAllCollections(data).subscribe((collections)=>{
    collectionList = collections.collectionlist;
    return collectionList;
  })
  // return collectionList;
}



noCollectionFlag :boolean = false;
noCollectionMessage : String = ""

@Input() loadBigListOfCollections:any = false;
loadSettingsPage(){
  this.loadCollectionsPartial = false;
  // this.loadBigListOfCollections = true;
  this.router.navigateByUrl('/settings?tab=2',{
    state:{}
  });

}


async loadCollectionInResultsPage(collection){

  if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
    var resp = await this.Auth.getMaintenanceStatus({
      "checkForMaintainence":true
    });
    // console.log("resp", resp);
         if(resp == "true"){
          this.router.navigateByUrl("/maintenance");
          return;
         }
        }
  this.updateCollectionsUsed(collection.id);

  let coll = {
    'userHasCollection':'false',
    'collectionObj': collection,
    'from':'settings'
  }

  this.router.navigateByUrl('/', {
    state: {
      collections: coll
    }
  });
}

updateCollectionsUsed(item:any)
{
  let payload = {
    "collectionID" : item
  }
  this.collectionsService.updateCollectionsUsed(payload).subscribe(async (data) => {
    // console.log("data", data);

});
}

async shareCollection(collection:any){

  if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
    var resp = await this.Auth.getMaintenanceStatus({
      "checkForMaintainence":true
    });
    // console.log("resp", resp);
         if(resp == "true"){
          this.router.navigateByUrl("/maintenance");
          return;
         }
        }
  let userFullName = this.cookieService.get("fn");
 // userFullName = userFullName? this.cryptoService.decryptData(userFullName):"";
  // // console.log("this.selectedDocumentDetails.Title",collection.name);
  var shareLink = CONFIG.COLLECTIONS.EMAIL_SHARE_LINK;
  var disclaimer_label = "";
  var disclaimer_txt = "";
  var email ='';
  var subject = 'GMSA Content Search Navigator: Asset share';
  var emailBody = userFullName +` has invited you to access and explore the collection(s):`;	

  //emailBody +=userFullName+" has invited you to access and explore the collections: "+encodeURIComponent(collection.name)+"%0D%0A Link:  "+encodeURIComponent("https://gmsasearch-dev.merck.com/share/collections/"+collection.id+"");
  //var link = linkUrlPart+docID;	
  console.log("this.selectionList.length", this.selectionList.length);
  if(this.selectionList.length>1){
    emailBody +="\n";
    for(let doc of this.selectionList){
      console.log("selected docs", this.selectionList);

      emailBody +="\n";
     var trimmedString = "";
      if(doc.name.length > 25){
        trimmedString = `${doc.name}`.substring(0, 25)+"...";
      }
      else{
        trimmedString = doc.name;
      }
    
      emailBody += trimmedString;	
      // emailBody +="\n";

     
      emailBody += "\n"+"Link: " + (shareLink+doc.id);
      emailBody +="\n";
    }
  }else{
    emailBody += `${collection.name} \n`;	
  //  emailBody +=userFullName+" has invited you to access and explore the collections: "+encodeURIComponent(collection.name)+"%0D%0A Link:  "+encodeURIComponent("https://gmsasearch-dev.merck.com/share/collections/"+collection.id+"");
  emailBody += "\n"+"Link: " + (shareLink+collection.id);	
  }
  emailBody +="\n";	
  emailBody = encodeURIComponent(emailBody);	
  emailBody +="%0D%0A %0D%0A"+this.disclaimer_label+"%0D%0A";
  emailBody += this.disclaimer_txt;
  document.location = "mailto:"+email+"?subject="+subject+"&body="+emailBody;
  
}

@Input() collectionName:any;
CollectionRenamePopupShow = false;
collection:any;


renameDescription:any;

getUpdatedDescription(data){
  //this.maxLength = CONFIG.SAVED_SEARCHES.MAX_LENGTH;
       this.renameDescription = data.target.value;
       
      
          if(this.renameDescription.length ==this.maxLength){
               this.color = "red";
           // this.descriptionValidationMessage = `Character Limit Exceeded. Max Characters: ${this.maxLength}`
            this.descriptionValidationMessage =`You may only enter up to ${this.maxLength} characters. Maximum number of characters reached.`
          }
          else{
            this.descriptionValidationMessage = ` ${this.maxLength - this.renameDescription.length}  characters remaining`;
            this.color= "black";
          }

}


// <<<<<<< feature/search-settings-collection-issue
async renameCollection(collection){
  this.descriptionValidationMessage = "";
  if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
    var resp = await this.Auth.getMaintenanceStatus({
      "checkForMaintainence":true
    });
    // console.log("resp", resp);
         if(resp == "true"){
          this.router.navigateByUrl("/maintenance");
          return;
         }
        }

// =======


// renameDescription:any;

// getUpdatedDescription(data){
//   //this.maxLength = CONFIG.SAVED_SEARCHES.MAX_LENGTH;
//        this.renameDescription = data.target.value;
       
      
//           if(this.renameDescription.length ==this.maxLength){
//                this.color = "red";
//             this.descriptionValidationMessage = `Character Limit Exceeded. Max Characters: ${this.maxLength}`
//           }
//           else{
//             this.descriptionValidationMessage = ` ${this.maxLength - this.renameDescription.length}  characters remaining`;
//             this.color= "black";
//           }

// }


// renameCollection(collection){
// >>>>>>> sit_to_dev_code
  if(!this.allCollections){
    this.loadAllCollections();
  }
  // console.log("collection", collection);
  
  this.CollectionRenamePopupShow = true;
  this.collectionName = collection.name;
  this.collection = collection;
  
  
}

inLineMsg = false;
RenameSuccessFlag = false;
renameCollectionInServer(collectionName){
  this.message = "";
  var isCollectionNameChanged;
        
       // console.log("this.renameValue", this.renameValue);
       // console.log(this.renameValue == undefined);

      //  if(this.renameValue == undefined || this.collection.name == this.renameValue){
      //   isCollectionNameChanged = false;
      //  }
      //  else{
      //   isCollectionNameChanged = true;
      //  }
      if( this.collection.name == collectionName){
        isCollectionNameChanged = false;
       }
       else{
        isCollectionNameChanged = true;
       }
       // console.log("description", this.renameDescription);

        if(this.renameDescription == undefined){
          this.renameDescription= this.collection.description
        }
        if(this.renameValue == undefined){
          this.renameValue= collectionName;
        }
       // console.log(" isCollectionNameChanged",isCollectionNameChanged)
       // console.log("this.renameValue",this.renameValue)
       // console.log("this.renameDescription",this.renameDescription)
       // console.log("collectionName", collectionName);
       // console.log("this.allCollections", this.allCollections);
  // alert("collectionName--->"+JSON.stringify(collectionName));
  this.disableElement("renameCollectionInServerId");
  if(!collectionName){
    // console.log("inside return");
       return;
  }
  if(this.allCollections){
    // let existCollection = this.allCollections.find(i => i.name === collectionName);
    // if(existCollection){
    //   this.inLineMsg = true;
    //   // this.message = "A collection with that name already exists. Please enter a different name."
    //   this.message = CONFIG.COLLECTIONS.COLLECTION_WITH_SAME_NAME;
    //   // this.successFlag = true;
    //   // this.CollectionRenamePopupShow = false;
    //   this.enableElement("renameCollectionInServerId");
    //   return;
    //  }
  }else{
    // this.getCollectionsList().subscribe(data=>{
    //   this.allCollections = data;
    //   let existCollection = this.allCollections.find(i => i.name === collectionName);
    //   if(existCollection){
    //     this.inLineMsg = true;
    //     this.message = "A collection with that name already exists. Please enter a different name."
    //     this.enableElement("renameCollectionInServerId");
    //     return;
    //  }
    // })
  }
  
  // console.log("rename value", this.renameValue);

  //return;

  var data = {
    name:collectionName,
  description:this.renameDescription,
  isCollectionNameChanged: isCollectionNameChanged,
  id:this.collection.id,
  items:[],
  userId:this.userId,
  operation:"UPDATE_COLLECTION",
  feature:"ALL"};
  // console.log("data", data);
 
  this.collectionsService.renameDeleteCollection(data).subscribe((res)=>{

    if(res.statusCode==1003){
      this.inLineMsg = true;
     // this.message = "A collection with that name already exists. Please enter a different name."
     this.message = CONFIG.COLLECTIONS.COLLECTION_WITH_SAME_NAME;
      // this.successFlag = true;
      // this.CollectionRenamePopupShow = false;
      this.enableElement("renameCollectionInServerId");
      return;
    }else{
// // console.log("res ---> "+JSON.stringify(res));
    this.sharedService.sendRenameCollection(res);
   // this.message = "Successfully renamed the Collection."
   this.message = CONFIG.COLLECTIONS.SUCCESSFUL_RENAME_COLLECTION;
    this.RenameSuccessFlag = true;
    this.CollectionRenamePopupShow = false;
    this.loadAllCollections();
    this.enableElement("renameCollectionInServerId");
    }

    // alert("Sort-order-->"+this.sortOrder);
    
  });

  this.inLineMsg = false;
  this.descriptionValidationMessage = "";
  
}

CollectionDeletePopupShow  =false;
CollectionDeletePopupMultipleShow  =false;
deleteCollectionNames:any = "";
async deleteCollectionWindow(collection){
  if(sessionStorage.getItem("maintenanceAccess")  == null || sessionStorage.getItem("maintenanceAccess") != "1") {
    var resp = await this.Auth.getMaintenanceStatus({
      "checkForMaintainence":true
    });
    // console.log("resp", resp);
         if(resp == "true"){
          this.router.navigateByUrl("/maintenance");
          return;
         }
        }
        if(this.selectionList.length > 0){
          this.CollectionDeletePopupMultipleShow = true;
          for(let i=0; i <this.selectionList.length; i++){
            this.deleteCollectionNames = this.deleteCollectionNames + this.selectionList[i].name + ", "; 
          }
         this.deleteCollectionNames = this.deleteCollectionNames.substring(0,(this.deleteCollectionNames.length -2));
          console.log("this.CollectionDeletePopupMultipleShow", this.deleteCollectionNames);
          
        }
        else{
          this.CollectionDeletePopupShow = true;
        }
  
  this.collection = collection;
}

async deleteMultiCollections(){
  this.disableElement("deleteCollectionsId");
  this.message = "Deletion in progress!";
       for(var collection of this.selectionList ){
        var payload =`{"name":"undefined","id":`+collection.id+`,"items":[],"userId":`+this.userId+`,"operation":"DELETE_COLLECTION","feature":"", "description":"", "isCollectionNameChanged": false}`;
    var response= await this.collectionsService.renameDeleteCollection(payload).toPromise();
    this.sharedService.sendDeletedCollection(this.message);
    console.log("response", response);
       }
       this.CollectionDeletePopupMultipleShow = false;
         this.successFlag = true;
       this.message = CONFIG.COLLECTIONS.SUCCESSFUL_DELETE_COLLECTION;
         this.selectionList = [];
         this.deleteCollectionNames= "";
        //  this.dom.getElementById("mainCheckBox").checked = false;
         
       this.loadAllCollections();

  this.enableElement("deleteCollectionsId");
}
deleteCollections(){
  this.disableElement("deleteCollectionsId");

  var payload =`{"name":"undefined","id":`+this.collection.id+`,"items":[],"userId":`+this.userId+`,"operation":"DELETE_COLLECTION","feature":"", "description":"", "isCollectionNameChanged": false}`;
  this.collectionsService.renameDeleteCollection(payload).subscribe((res)=>{
    this.sharedService.sendDeletedCollection(this.message);
    // // console.log("res ---> "+JSON.stringify(res));
    // this.message = "Successfully deleted the Collection."
    this.message = CONFIG.COLLECTIONS.SUCCESSFUL_DELETE_COLLECTION
    this.successFlag = true;
    this.CollectionDeletePopupShow = false;
    this.loadAllCollections();
    this.enableElement("deleteCollectionsId");
    
  });
}


clearAll(){
  const engine = this.engineService.getEngibeObj();
  
  var facetState = engine.state.facetSet;
    var facetStateDate = engine.state.dateFacetSet;
    // console.log("facetState--->"+JSON.stringify(facetState));

    for (const k in facetState) {
      // console.log("k--->"+JSON.stringify(facetState[k].request.facetId));
      var toggleSelectFacetValueaction1 = loadFacetSetActions(
      engine
    ).deselectAllFacetValues(facetState[k].request.facetId);
    engine.dispatch(toggleSelectFacetValueaction1);
    }

    var toggleSelectFacetValueaction2 = loadFacetSetActions(
      engine
    ).deselectAllFacetValues("contentapprovaldate_input");
    engine.dispatch(toggleSelectFacetValueaction2);


    const action = loadAdvancedSearchQueryActions(engine).updateAdvancedSearchQueries({aq: '',});
    engine.dispatch(action);

    const actionDf = loadAdvancedSearchQueryActions(engine).updateAdvancedSearchQueries({dq: '',});
    engine.dispatch(actionDf);

    const actionlq = loadAdvancedSearchQueryActions(engine).updateAdvancedSearchQueries({lq: '',});
    engine.dispatch(actionlq);

    const actioncq = loadAdvancedSearchQueryActions(engine).updateAdvancedSearchQueries({cq: '',});
    engine.dispatch(actioncq);

    const { updateQuery } = loadQueryActions(engine);
    const { executeSearch } = loadSearchActions(engine);
    const { logSearchboxSubmit } = loadSearchAnalyticsActions(engine);
    engine.dispatch(updateQuery({q:''}));
  
    engine.dispatch(executeSearch(logSearchboxSubmit()));
    engine.executeFirstSearch();

}


wait(ms){
  var start = new Date().getTime();
  var end = start;
  while(end < start + ms) {
    end = new Date().getTime();
 }
}


closeMsgPopup(){
  this.successFlag = false;
  // this.cookieService.set('selectedDocuments',	"");
  // localStorage.setItem('selectedDocuments',	"");
  this.sharedService.sendCollectionUpdate(this.message);
}

closeRenameMsgPopup(){
  this.RenameSuccessFlag = false;
  // this.cookieService.set('selectedDocuments',	"");
  // localStorage.setItem('selectedDocuments',	"");
  // this.sharedService.sendCollectionUpdate(this.message);
}

closeAndRefresh(){
  this.successFlag = false;
  // this.cookieService.set('selectedDocuments',	"");
  localStorage.setItem('selectedDocuments',	"");
  this.sharedService.sendCollectionUpdate(this.message);
}

keyDownValidator(event){
  // alert(JSON.stringify(this.collectionName));
  // this.collectionName.trimStart();
  if(this.collectionName){
    if(this.collectionName.charAt(0)==' ' || this.collectionName.charAt(0)==' '){
      this.collectionName = this.collectionName.trim();
      event.preventDefault();
    }
  }
}

// custom events 
customEventLoadSpecificCollection(collectionName:any)
{
  this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Click',Title : collectionName},'MyCollections-Menu');

}

customEventsSeeAllCollections()
{
  this.eventLoggingService.logCustomEvent('Results Page', { userId: this.cookieService.get('usi'),Action:'Click',Cause : "MyCollections"},'MyCollections-Menu-See-All');

}

customEventRenameCollection(collectionName:any)
{
  this.eventLoggingService.logCustomEvent('Settings Page Menu', { userId: this.cookieService.get('usi'),Action:'Click',Details : collectionName},'MyCollections-Rename');

}

customEventShareCollection(collectionName:any)
{
  this.eventLoggingService.logCustomEvent('Settings Page Menu', { userId: this.cookieService.get('usi'),Action:'Click',Details : collectionName},'MyCollections-Share');

}

customEventDeleteCollection(collectionName:any)
{
  this.eventLoggingService.logCustomEvent('Settings Page Menu', { userId: this.cookieService.get('usi'),Action:'Click',Details : collectionName},'MyCollections-Delete');

}

customEventSpecificCollection(collectionName:any)
{
  this.eventLoggingService.logCustomEvent('Settings Page Menu', { userId: this.cookieService.get('usi'),Action:'Click',Details : collectionName},'MyCollections-Specific');

}




}
