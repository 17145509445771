<div id="showSelectedFlag" *ngIf="showAllSelectedFlag" style="display:  flex; align-items:  center;">
    <div>
        <!-- <input style="width:  16px; height:  16px;" id="showSelected" type="checkbox" (click)="showAllSelected($event)" />  -->
        <img
            id="showSelected"
            (click)="showAllSelected($event)"
            src="../../assets/merck-assets/viewselecteddocs.svg"
            title="Click to view the selected content"
            style="width:  25px; height:  25px; position:  relative; top:  4px; cursor:  pointer; margin-bottom:  5px; margin-left:  20px;"
        />
    </div>

    

    <div *ngIf="backToResults" class="selectedDocsBackBtn" (click)="clearshowAllSelected()">Back to results</div>
</div>
<div style="background-color:  #ffffff; margin-bottom:  10px; border:  1px solid #e2e2e2;" *ngFor="let result of results;let i = index">
    <div class="item-container">
        <div style="padding:  20px 20px 0px; border-radius:  4px;">
            <div style="display:  flex; align-items:  center;">
                <div *ngIf="result">
            <input *ngIf="isRockyMountainUser == false" style="width: 16px; height: 16px;" class="checkbox-doc-select" id="myCheckbox" id="{{result['raw']['spouniqueid'] ? result['raw']['spouniqueid']:result['raw']['contentsourceid']}}" type="checkbox" (change)="onSelectChange(result,$event)" [checked]="isSelected(result['raw']['spouniqueid'] || result['raw']['contentsourceid'])" />
             <!-- <label for="checkbox1">Checkbox</label> -->
                </div>
                <div>
                    <div style="width:  auto; height:  auto; color:  #57636c; border-radius:  4px; background:  #e7e8ea; font-weight:  400; font-size:  11px; line-height:  15px; margin-left:  20px; padding:  4px 12px 0px; font-family:  invention;">
                      {{ result.raw['source'] }}
                    </div>
                  </div>
                <div style="height:  18px; width:  18px; margin-left:  20px;" *ngIf=" !result.raw['submitter'] &&  result.raw['valueevidencesynthesisandcommunication']!='Evidence Synthesis Activities' && result.raw['valueevidencedataandresearch']!='Non-Interventional Studies' && result.raw['source'] !== 'Value Evidence Environmental Scan' && result.raw['source'] !== 'Veeva Vault PromoMats'">
                    <!-- <img style="width:  100%; height:  100%; object-fit:  contain; margin-top:  -7px;" [src]="getFileTypeImage(result)" /> -->
                   <img style="width:  100%; height:  100%; object-fit:  contain; margin-top:  0px;" src="{{'./../../assets/' + result.raw['filetype'] + '.svg'}}" />
                    
                </div>

                <div *ngIf="!result.raw['submitter'] && result.raw['valueevidencesynthesisandcommunication']!='Evidence Synthesis Activities' && result.raw['valueevidencedataandresearch']!='Non-Interventional Studies'  && result.raw['source'] !== 'Value Evidence Environmental Scan' && result.raw['source'] !== 'Veeva Vault PromoMats'" style="height:  18px; width:  18px; margin-left:  5px; margin-top:  7px; font-weight:  400; color:  rgba(128, 144, 156, 1); text-transform:  uppercase; font-family:  invention;">{{result.raw['filetype']}}</div>
            </div>
            <div class="item" style="display:  flex; height:  auto; width:  100%; border-bottom:  1px solid #ebebeb;">
                <div style="width:  95%; display:  flex; flex-direction:  column; flex:  1;">
                    <div *ngIf="result.raw['source']!='Veeva Vault MedComms' || (result.raw['valueevidencesynthesisandcommunication']=='Evidence Synthesis Activities' || result.raw['valueevidencedataandresearch']=='Non-Interventional Studies')" style="font-size:  17px; font-family:  invention; font-weight:  400; color:  #00857c; padding:  16px 0px;">
                        <span><a style="text-decoration:  none; color:  #00857c; cursor:  pointer;" (click)="openLink(result,i)">{{ result.title || 'N/A' }}</a></span>
                    <!-- <span>{{i}}</span> -->
                    </div>
                    <div *ngIf="result.raw['source']=='Veeva Vault MedComms' && (result.raw['valueevidencesynthesisandcommunication']!='Evidence Synthesis Activities' && result.raw['valueevidencedataandresearch']!='Non-Interventional Studies')" style="font-size:  17px; font-family:  invention; font-weight:  400; color:  #00857c; padding:  16px 0px;" (click)="openViewPage(result,i)">
                        <span style="cursor:  pointer;">{{ result.title || 'N/A'  }}</span>
                    </div>
                    <!-- VEES Details -->

                    <div *ngIf="result.raw['source']=='Value Evidence Environmental Scan'" id="vees">
                        <div style="display:  flex; height:  auto;">
                            <div style="width:  50%;">
                                <div class="field"><span class="field-label">Country:  </span><span class="field-value">{{ result.raw['vees_geographic_focus'] || 'N/A' }}</span></div>
                                <div class="field"><span class="field-label">Company:  </span> <span class="field-value">{{ result.raw['vees_company'] || 'N/A' }}</span></div>
                                <div class="field"><span class="field-label">Therapeutic Area:  </span><span class="field-value">{{ result.raw['vees_therapyarea'] || 'N/A' }}</span></div>
                            </div>
                            <div style="width:  50%;">
                                <div class="field"><span class="field-label">VEES Category:  </span><span class="field-value">{{ result.raw['vees_category'] || 'N/A' }}</span></div>
                                <div class="field"><span class="field-label">Steward/Contact:  </span>
                                    <a href="mailto: darrell_penn@merck.com">
                                        <span>Darrell Penn</span>
                                        <!-- External opener icon -->
                                        <img style="margin-bottom: -1.5px;" src="../../assets/external-link-blue.svg" alt="External Icon" width="15" height="10">
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="insights">
                            <p><strong><span class="field-label">Insights: </span></strong><span class="field-value">{{ result.raw['vees_insights'] || 'N/A' }}</span> </p>
                        </div> -->
                        <div *ngIf="result.raw['vees_insights']" style="margin-left: 3px;">
                            <div class="insightsContent" *ngIf="removeHTMLContentVEES(result.raw['vees_insights']) > 50">
                                <div class="field">
                                    <strong><span class="field-label">Insights: </span></strong>
                                    <span *ngIf="!insightsExpanded" class="field-value">
                                        {{ result.raw['vees_insights'] | removehtml | slice: 0: 110 }}...
                                        <a style="color:  teal" href="javascript: void(0)" (click)="toggleInsights()">
                                            {{ insightsExpanded ? 'Read Less' :  'Read More' }}
                                        </a>
                                    </span>
                                    <span *ngIf="insightsExpanded" class="field-value">
                                        {{ result.raw['vees_insights'] | removehtml }}
                                        <a style="color:  teal" href="javascript: void(0)" (click)="toggleInsights()">
                                            {{ insightsExpanded ? 'Read Less' :  'Read More' }}
                                        </a>
                                    </span>
                                </div>
                            </div>
                            <div class="insightsContent" *ngIf="removeHTMLContentVEES(result.raw['vees_insights']) < 50">
                                <div class="field">
                                    <strong><span class="field-label">Insights: </span></strong>
                                    <span class="field-value">
                                        {{ result.raw['vees_insights'] }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="!result.raw['vees_insights']" class="insightsLayout">
                            <div class="insights">
                                <div class="field"><strong><span class="field-label">Insights:  </span></strong><span class="field-value">N/A</span></div>
                            </div>
                        </div>
                    </div>
                    
                         
<!-- <<<<<<< feature/multiselectContent
                  
              </div>
          </div>
          <div style="    margin-left:  -20px;
          margin-right:  52px;
          display:  flex;
          justify-content:  space-between;
          background:  white;
          width:  100%;
          height:  51px;
          align-items:  center;
          padding:  0px 20px 0px;
          border-top: 1px solid #ebebeb">
              <span class="buttonsStyle">
                  <div style="display:  flex;">
                      <div><app-quick-view [imageUrl]="imageUrl" [result]="result"></app-quick-view></div>
                  </div>
              </span>
              <span class="vertical-border"></span>
              <span class="buttonsStyle">
                  <span class="botton-action-icons">
                      <a style="text-decoration:  none; color:  black;" href="{{result.clickUri}}" target="_blank">
                          <span class="material-icons outlined" style="color:  #00857c;">
                            open_in_new
                           </span>
                      </a>
                  </span>
                  <span class="botton-action-labels">
                    <a style="text-decoration:  none; color:  black;" href="{{result.clickUri}}" target="_blank">Open in Source</a></span>
              </span>
              <span class="vertical-border"></span>

              <span class="buttonsStyle">
                  <span (click)="copylink(result)" class="botton-action-icons" style="color:  #00857c;">
                    <span class="material-icons outlined">
                        link  <<<<<<< feature/multiselectContent  -->
<!-- ======= -->

<!-- <div style="display:  flex; height:  auto;">
    <div style="width:  50%;">
        <div class="field" *ngIf="result.raw['contentintendeduse']"><span class="field-label">Intended Use: </span><span class="field-value">{{result.raw['contentintendeduse']}}</span></div>
    </div>
</div>   -->
<div class="nis-tabbed" *ngIf="result.raw['valueevidencedataandresearch']=='Non-Interventional Studies'">
    <input checked="true" type="radio" id="nis-tab1-{{result.raw['veap_obj_id']}}" name="css-tabs-{{result.raw['veap_obj_id']}}">
    <input type="radio" id="nis-tab2-{{result.raw['veap_obj_id']}}" name="css-tabs-{{result.raw['veap_obj_id']}}">
    
    <ul class="nis-tabs">
        <li class="nis-tab"><label for="nis-tab1-{{result.raw['veap_obj_id']}}">Details</label></li>
        <li class="nis-tab"><label for="nis-tab2-{{result.raw['veap_obj_id']}}">Documents and Timeline</label></li>
    </ul>
    
    <div class="nis-tab-content">
        <!-- <h4>Details</h4> -->
        

        <div style="display:  flex; height:  auto;">
            <div style="width:  50%;">
                <div class="field" *ngIf="result.raw['veapcountry']"><span class="field-label">Country: </span><span class="field-value">{{result.raw['veapcountry'] || 'N/A'}}</span></div>
                <div class="field" *ngIf="result.raw['veapproduct']"><span class="field-label">Name of Product: </span><span class="field-value">{{result.raw['veapproduct']}}</span></div>
                <div class="field" *ngIf="result.raw['veapindication']"><span class="field-label">Indication/Disease: </span><span class="field-value">{{result.raw['veapindication']}}</span></div>
            </div>
        
            <div style="width:  50%;">
                <div class="field" *ngIf="result.raw['veapactivitytype']"><span class="field-label">Activity Type: </span><span class="field-value">{{result.raw['veapactivitytype']}}</span></div>
                <div class="field" *ngIf="result.raw['veapcontact']"><span class="field-label">Steward/Contact: </span><span class="field-value">{{result.raw['veapcontact']}}</span></div>
            </div>
        </div>

        <div>
            <details *ngIf="!result.raw['submitter'] && result.raw['source'] !== 'Veeva Vault PromoMats'">
                <summary style="color:  teal; padding:  0px 4px 10px; font-family:  invention; font-size:  13px; cursor:  pointer;">More Details</summary>
                <div style="display:  flex; height:  auto; padding:  0rem 1rem 1rem;">
                    <div style="width:  100%;">

                        <div class="more-field" *ngIf="result.raw['veapisldg']"><span class="field-label">Is LDG?: </span><span class="field-value">{{result.raw['veapisldg']}}</span></div>
                        <div class="more-field" *ngIf="result.raw['veaptherapeuticarea']"><span class="field-label">Therapeutic Area: </span><span class="field-value">{{result.raw['veaptherapeuticarea']}}</span></div>
                        <div class="more-field" *ngIf="result.raw['veapcustomerbusline']"><span class="field-label">Customer Business Line: </span><span class="field-value">{{result.raw['veapcustomerbusline']}}</span></div>
                        <div class="more-field" *ngIf="result.raw['veapmkvnumber']"><span class="field-label">MK/V Number: </span><span class="field-value">{{result.raw['veapmkvnumber']}}</span></div>
                        <div class="more-field" *ngIf="result.raw['veapstudyobjective']"><span class="field-label">Study Objective: </span><span class="field-value">{{result.raw['veapstudyobjective']}}</span></div>
                        
                    </div>
                </div>
            </details>
            <!-- <div class="item-bottom" style="margin-top:  auto;"></div> -->
        </div>

    </div>
    
    <div class="nis-tab-content">


            <div class="wrapper-nis">
                <div class="box-nis">Deliverables</div>
                <div class="box-nis">Classification</div>
                <div class="box-nis">Date

                    <div style="display:  inline; float:  right;">
                        <button class="expand-collapse" id="expand_{{result.raw['veap_obj_id']}}" (click)="expandSteps(result.raw['veap_obj_id'])">Expand All<span style="color:  #00857C;">&#9660;</span></button>
                        <button class="expand-collapse collapse" id="collapse_{{result.raw['veap_obj_id']}}" (click)="collapseSteps(result.raw['veap_obj_id'])">Collapse All<span style="color:  #00857C;">&#9650;</span></button>
                    </div>
                    

                </div>
            
            
                <div class="box-nis" >
                    <div class="step" id="concept_sheet_{{result.raw['veap_obj_id']}}">
                        <div class="v-stepper">
                          <div class="circle"></div>
                          <div class="line"></div>
                        </div>
                  
                        <details  id="details_concept_sheet_{{result.raw['veap_obj_id']}}">
                            <summary style="padding:  5px 4px 10px; font-family:  invention; font-size:  13px; cursor:  pointer; font-weight:  400;">Concept Sheet</summary>
                            <div *ngFor="let i of convertStringToJson(result.raw['relateddocuments'])">
                                <div *ngIf="i.type__v=='Study Concept'">
                                   <div style="font-size:  12px;font-weight:  600;padding:  12px; cursor:  pointer; color:  #00857C;" (click)="openNISViewPage(i)">{{i.name__v}}
                                    <span (click)="downloadFile(i)" class="material-icons outlined" style="color:  #00857c; font-size: 15px; line-height: 0; position: relative; top: 4px; left:1rem;" title="Click to download file">download</span>
                                </div>
                                   {{nisStepFill('concept_sheet_'+result.raw['veap_obj_id'])}}
                                   <!-- {{fillEndorsedYear('endorsedyear_'+result.raw['veap_obj_id'],i.year__c)}} -->
                                   <!-- <div>Type:  {{i.type__v}}</div> -->
                                   <!-- <div>Classification:  Concept Sheets</div> -->
                                </div>
                            </div>
                        </details>
            
                        
                    </div>
                </div>
                <!-- <div class="box-nis" style="font-weight:  400;">Concept Sheets</div> -->
                <div class="box-nis" style="font-weight:  400;">N/A</div>
                <!-- <div class="box-nis" id="endorsedyear_{{result.raw['veap_obj_id']}}" style="font-weight:  400;"> Endorsed Year{{result.raw['endorsedyear']}}</div> -->
                <div class="box-nis" style="font-weight:  400; display:  flex; flex-direction:  column;">
                    <span id="endorsedyear_{{result.raw['veap_obj_id']}}">{{result.raw['endorsedyear']}}</span>
                    <span  id="endorsedyear_{{result.raw['veap_obj_id']}}_txt" style="font-size:  .75em; color:  #708b99;"></span>
                </div>
            
            
            
                <div class="box-nis"><!-- active -->
                    <div class="step" id="study_protocol_{{result.raw['veap_obj_id']}}">
                      <div class="v-stepper">
                        <div class="circle"></div>
                        <div class="line"></div>
                      </div>
                  
            
                      <details id="details_study_protocol_{{result.raw['veap_obj_id']}}">
                        <summary style="padding:  5px 4px 10px; font-family:  invention; font-size:  13px; cursor:  pointer; font-weight:  400;">Study Protocol</summary>
                        <div *ngFor="let i of convertStringToJson(result.raw['relateddocuments'])">
                            <div *ngIf="i.type__v=='Non-Interventional Study Documents' && i.subtype__v=='Protocols' && i.classification__v=='Final Study Protocol'">
                                <div style="display:  grid;">
                                        <div  style="font-size:  12px;font-weight:  600;padding:  12px; cursor:  pointer; color:  #00857C;" (click)="openNISViewPage(i)">{{i.name__v}}
                                            <span (click)="downloadFile(i)" class="material-icons outlined" style="color:  #00857c; font-size: 15px; line-height: 0; position: relative; top: 4px; left:1rem;" title="Click to download file">download</span>
                                        </div>
                                </div>
                                
                                
                                {{nisStepFill('study_protocol_'+result.raw['veap_obj_id'])}}
                                <!-- <div>Type:  {{i.type__v}}</div> -->
                                <!-- <div>Classification:  Final Study Protocol</div> -->
            
                            </div>
                            
                        </div>
                    </details>
            
                      
                    </div></div>
                <div class="box-nis" style="font-weight:  400;">Final Study Protocol</div>
                <div class="box-nis" style="font-weight:  400; display:  flex; flex-direction:  column;">
                    <!-- <span *ngIf="result.raw['nisapprovaldate']">{{result.raw['nisapprovaldate'] | date :  "MMMM-dd-yyyy"}}</span> -->
                    <span *ngIf="result.raw['nisstudyprotocaldate']">{{result.raw['nisstudyprotocaldate'] | date :  "MMMM-dd-yyyy": 'UTC'}}</span>
                    <span *ngIf="!result.raw['nisstudyprotocaldate']">N/A</span>
                    <span *ngIf="result.raw['nisstudyprotocaldate']" style="font-size:  .75em; color:  #708b99;">Protocol Approval Date</span>
                </div>
            
            
                <div class="box-nis"><!-- regular -->
                    <div class="step" id="study_reports_{{result.raw['veap_obj_id']}}">
                        <div class="v-stepper">
                          <div class="circle"></div>
                          <!-- <div class="line"></div> -->
                        </div>
                  
            
                        <details id="details_study_reports_{{result.raw['veap_obj_id']}}">
                            <summary style="padding:  5px 4px 10px; font-family:  invention; font-size:  13px; cursor:  pointer; font-weight:  400;">Study Reports</summary>
                            <div *ngFor="let i of convertStringToJson(result.raw['relateddocuments'])">
                                <!-- <div *ngIf="i.type__v=='Non-Interventional Study Documents' && i.subtype__v=='Protocols' && i.classification__v=='Final Study Protocol'"> -->
                                <div *ngIf="i.type__v=='Non-Interventional Study Documents' && i.subtype__v=='Study Reports' && i.classification__v=='Interim and Final Study Reports'">
                                    <div style="display:  grid;">
                                        <div  style="font-size:  12px;font-weight:  600;padding:  12px; cursor:  pointer; color:  #00857C;" (click)="openNISViewPage(i)">{{i.name__v}}
                                            <span (click)="downloadFile(i)" class="material-icons outlined" style="color:  #00857c; font-size: 15px; line-height: 0; position: relative; top: 4px; left:1rem;" title="Click to download file">download</span>
                                        </div>
                                    </div>

                                    {{nisStepFill('study_reports_'+result.raw['veap_obj_id'])}}
                                    <!-- {{nisStepFill('study_protocol_'+result.raw['veap_obj_id'])}} -->
                                    <!-- <div>Type:  {{i.type__v}}</div> -->
                                    <!-- <div>Classification:  Final Study Protocol</div> -->
                
                                </div>
                                
                            </div>
                        </details>
            
                       
                    </div></div>
                <div class="box-nis" style="font-weight:  400;">Interim and Final Study Reports</div>
                <!-- <div class="box-nis" style="font-weight:  400;">{{result.raw['niscompletiondate'] | date}}</div> -->
                <div class="box-nis" style="font-weight:  400; display:  flex; flex-direction:  column;">
                    <span *ngIf="result.raw['nisstudyreportdate']">{{result.raw['nisstudyreportdate'] | date :  "MMMM-dd-yyyy": 'UTC'}}</span>
                    <span *ngIf="!result.raw['nisstudyreportdate']">N/A</span>
                    <span *ngIf="result.raw['nisstudyreportdate']" style="font-size:  .75em; color:  #708b99;">Protocol Completion Date</span>
                </div>
            
            
            </div>






            <div style="display:  flex; height:  auto;">
                <div style="width:  50%;">
                    <div *ngIf="result.raw['nisapprovaldate']" class="field" ><span class="field-label">Planned/Actual Protocol Approval Date: </span><span class="field-value">{{result.raw['nisapprovaldate'] | date: 'MMMM-dd-yyyy': 'UTC'}}</span></div>
                    <div *ngIf="!result.raw['nisapprovaldate']" class="field" ><span class="field-label">Planned/Actual Protocol Approval Date: </span><span class="field-value">N/A</span></div>
                    <div *ngIf="result.raw['niscompletiondate']" class="field" ><span class="field-label">Planned/Actual Report Completion Date: </span><span class="field-value">{{result.raw['niscompletiondate'] | date: 'MMMM-dd-yyyy': 'UTC'}}</span></div>
                    <div *ngIf="!result.raw['niscompletiondate']" class="field" ><span class="field-label">Planned/Actual Report Completion Date: </span><span class="field-value">N/A</span></div>
                    <div class="field" *ngIf="result.raw['nisactivitystatus']"><span class="field-label">Activity Status: </span><span class="field-value">{{result.raw['nisactivitystatus']}}</span></div>
                    <div class="field" *ngIf="result.raw['veap_obj_title']"><span class="field-label">REV/OPS ID: </span><span class="field-value">{{result.raw['veap_obj_title']}}</span></div>
                </div>
            </div>


            










    </div>
</div>











<div class="nis-tabbed" *ngIf="result.raw['valueevidencesynthesisandcommunication']=='Evidence Synthesis Activities'">
    <input checked="true" type="radio" id="nis-tab1-{{result.raw['veap_obj_id']}}" name="css-tabs-{{result.raw['veap_obj_id']}}">
    <input type="radio" id="nis-tab2-{{result.raw['veap_obj_id']}}" name="css-tabs-{{result.raw['veap_obj_id']}}">
    
    <ul class="nis-tabs">
        <li class="nis-tab"><label for="nis-tab1-{{result.raw['veap_obj_id']}}">Details</label></li>
        <li class="nis-tab"><label for="nis-tab2-{{result.raw['veap_obj_id']}}">Documents and Timeline</label></li>
    </ul>
    
    <div class="nis-tab-content">
        <!-- <h4>Details</h4> -->
        

        <div style="display:  flex; height:  auto;">
            <div style="width:  50%;">
                <div class="field" *ngIf="result.raw['veapcountry']"><span class="field-label">Country: </span><span class="field-value">{{result.raw['veapcountry']}}</span></div>
                <div class="field" *ngIf="result.raw['veapproduct']"><span class="field-label">Name of Product: </span><span class="field-value">{{result.raw['veapproduct']}}</span></div>
                <div class="field" *ngIf="result.raw['veapindication']"><span class="field-label">Indication/Disease: </span><span class="field-value">{{result.raw['veapindication']}}</span></div>
            </div>
        
            <div style="width:  50%;">
                <div class="field" *ngIf="result.raw['veapactivitytype']"><span class="field-label">Activity Type: </span><span class="field-value">{{result.raw['veapactivitytype']}}</span></div>
                <div class="field" *ngIf="result.raw['veapcontact']"><span class="field-label">Steward/Contact: </span><span class="field-value">{{result.raw['veapcontact']}}</span></div>
            </div>
        </div>

        <div>
            <details *ngIf="!result.raw['submitter'] && result.raw['source'] !== 'Veeva Vault PromoMats'">
                <summary  style="color:  teal; padding:  0px 4px 10px; font-family:  invention; font-size:  13px; cursor:  pointer;">More Details</summary>
                <div style="display:  flex; height:  auto; padding:  0rem 1rem 1rem;">
                    <div style="width:  100%;">

                        <div class="more-field" *ngIf="result.raw['veapisldg']"><span class="field-label">Is LDG?: </span><span class="field-value">{{result.raw['veapisldg']}}</span></div>
                        <div class="more-field" *ngIf="result.raw['veaptherapeuticarea']"><span class="field-label">Therapeutic Area: </span><span class="field-value">{{result.raw['veaptherapeuticarea']}}</span></div>
                        <div class="more-field" *ngIf="result.raw['veapcustomerbusline']"><span class="field-label">Customer Business Line: </span><span class="field-value">{{result.raw['veapcustomerbusline']}}</span></div>
                        <div class="more-field" *ngIf="result.raw['veapmkvnumber']"><span class="field-label">MK/V Number: </span><span class="field-value">{{result.raw['veapmkvnumber']}}</span></div>
                        <div class="more-field" *ngIf="result.raw['veapstudyobjective']"><span class="field-label">Study Objective: </span><span class="field-value">{{result.raw['veapstudyobjective']}}</span></div>
                        
                    </div>
                </div>
            </details>
            <!-- <div class="item-bottom" style="margin-top:  auto;"></div> -->
        </div>

    </div>
    
    <div class="nis-tab-content">


            <div class="wrapper-nis">
                <div class="box-nis">Deliverables</div>
                <div class="box-nis">Classification</div>
                <div class="box-nis">Date

                    <div style="display:  inline; float:  right;">
                        <button class="expand-collapse" id="expand_{{result.raw['veap_obj_id']}}" (click)="expandSteps(result.raw['veap_obj_id'])">Expand All<span style="color:  #00857C;">&#9660;</span></button>
                        <button class="expand-collapse collapse" id="collapse_{{result.raw['veap_obj_id']}}" (click)="collapseSteps(result.raw['veap_obj_id'])">Collapse All<span style="color:  #00857C;">&#9650;</span></button>
                    </div>

                </div>
            
            
                <div class="box-nis" >
                    <div class="step" id="concept_sheet_{{result.raw['veap_obj_id']}}">
                        <div class="v-stepper">
                          <div class="circle"></div>
                          <div class="line"></div>
                        </div>
                  
                        <details id="details_concept_sheet_{{result.raw['veap_obj_id']}}">
                            <summary style="padding:  5px 4px 10px; font-family:  invention; font-size:  13px; cursor:  pointer; font-weight:  400;">Concept Sheet</summary>
                            <div *ngFor="let i of convertStringToJson(result.raw['relateddocuments'])">
                                <div *ngIf="i.type__v=='Study Concept' || i.type__v=='Study'">
                                   <div style="font-size:  12px;font-weight:  600;padding:  12px; cursor:  pointer; color:  #00857C;" (click)="openNISViewPage(i)">{{i.name__v}}
                                    <span (click)="downloadFile(i)" class="material-icons outlined" style="color:  #00857c; font-size: 15px; line-height: 0; position: relative; top: 4px; left:1rem;" title="Click to download file">download</span>
                                </div>
                                   
                                   <!-- {{fillEndorsedYear('endorsedyear_'+result.raw['veap_obj_id'],i.year__c)}} -->
                                   {{nisStepFill('concept_sheet_'+result.raw['veap_obj_id'])}}
                                   <!-- <div>Type:  {{i.type__v}}</div> -->
                                   <!-- <div>Classification:  Concept Sheets</div> -->
                                </div>
                            </div>
                        </details>
            
                        
                    </div>
                </div>
                <!-- <div class="box-nis" style="font-weight:  400;">Concept Sheets</div> -->
                <div class="box-nis" style="font-weight:  400;">N/A</div>
                <!-- <div class="box-nis" id="endorsedyear_{{result.raw['veap_obj_id']}}" style="font-weight:  400;"> Endorsed Year{{result.raw['endorsedyear']}}</div> -->
                <div class="box-nis" style="font-weight:  400; display:  flex; flex-direction:  column;">
                    <span id="endorsedyear_{{result.raw['veap_obj_id']}}">{{result.raw['endorsedyear']}}</span>
                    <span  id="endorsedyear_{{result.raw['veap_obj_id']}}_txt" style="font-size:  .75em; color:  #708b99;"></span>
                </div>
            
            
            
                <div class="box-nis"><!-- active -->
                    <div class="step" id="study_protocol_{{result.raw['veap_obj_id']}}">
                      <div class="v-stepper">
                        <div class="circle"></div>
                        <div class="line"></div>
                      </div>
                  
            
                      <details id="details_study_protocol_{{result.raw['veap_obj_id']}}">
                        <summary style="padding:  5px 4px 10px; font-family:  invention; font-size:  13px; cursor:  pointer; font-weight:  400;">Study Protocol</summary>
                        <div *ngFor="let i of convertStringToJson(result.raw['relateddocuments'])">
                            <div *ngIf="i.type__v=='Evidence Synthesis Activities Documents' && i.subtype__v=='Protocols' && i.classification__v=='Final Study Protocol'">
                                <div style="display:  grid;">
                                        <div  style="font-size:  12px;font-weight:  600;padding:  12px; cursor:  pointer; color:  #00857C;" (click)="openNISViewPage(i)">{{i.name__v}}
                                            <span (click)="downloadFile(i)" class="material-icons outlined" style="color:  #00857c; font-size: 15px; line-height: 0; position: relative; top: 4px; left:1rem;" title="Click to download file">download</span>
                                        </div>
                                </div>
                                
                                
                                {{nisStepFill('study_protocol_'+result.raw['veap_obj_id'])}}
                                <!-- <div>Type:  {{i.type__v}}</div> -->
                                <!-- <div>Classification:  Final Study Protocol</div> -->
            
                            </div>
                            
                        </div>
                    </details>
            
                      
                    </div></div>
                <div class="box-nis" style="font-weight:  400;">Final Study Protocol</div>
                <!-- <div class="box-nis" style="font-weight:  400;">{{result.raw['nisapprovaldate'] | date}}</div> -->
                <div class="box-nis" style="font-weight:  400; display:  flex; flex-direction:  column;">
                    <span *ngIf="result.raw['nisstudyprotocaldate']">{{result.raw['nisstudyprotocaldate'] | date :  "MMMM-dd-yyyy": 'UTC'}}</span>
                    <span *ngIf="!result.raw['nisstudyprotocaldate']">N/A</span>
                    <span *ngIf="result.raw['nisstudyprotocaldate']" style="font-size:  .75em; color:  #708b99;">Protocol Approval Date</span>
                </div>
            
            
                <div class="box-nis"><!-- regular -->
                    <div class="step" id="study_reports_{{result.raw['veap_obj_id']}}">
                        <div class="v-stepper">
                          <div class="circle"></div>
                          <div class="line"></div>
                        </div>
                  
            
                        <details id="details_study_reports_{{result.raw['veap_obj_id']}}" >
                            <summary style="padding:  5px 4px 10px; font-family:  invention; font-size:  13px; cursor:  pointer; font-weight:  400;">Technical Report</summary>
                            <div *ngFor="let i of convertStringToJson(result.raw['relateddocuments'])">
                                <div *ngIf="i.type__v=='Evidence Synthesis Activities Documents' && (i.subtype__v=='Technical Report')">
                
                                    <div style="display:  grid;">
                                        <div  style="font-size:  12px;font-weight:  600;padding:  12px; cursor:  pointer; color:  #00857C;" (click)="openNISViewPage(i)">{{i.name__v}}
                                            <span (click)="downloadFile(i)" class="material-icons outlined" style="color:  #00857c; font-size: 15px; line-height: 0; position: relative; top: 4px; left:1rem;" title="Click to download file">download</span>
                                        </div>
                                    </div>

                                    {{nisStepFill('study_reports_'+result.raw['veap_obj_id'])}}
                                    <!-- {{nisStepFill('study_protocol_'+result.raw['veap_obj_id'])}} -->
                                    <!-- <div>Type:  {{i.type__v}}</div> -->
                                    <!-- <div>Classification:  Final Study Protocol</div> -->
                
                                </div>
                                
                            </div>
                        </details>
            
                       
                    </div></div>
                <div class="box-nis" style="font-weight:  400;">N/A</div>
                <!-- <div class="box-nis" style="font-weight:  400;">{{result.raw['niscompletiondate'] | date}}</div> -->
                <div  class="box-nis" style="font-weight:  400; display:  flex; flex-direction:  column;">
                    <span *ngIf="result.raw['esatechnicalreportdate']">{{result.raw['esatechnicalreportdate'] | date :  "MMMM-dd-yyyy": 'UTC'}}</span>
                    <span *ngIf="!result.raw['esatechnicalreportdate']">N/A</span>
                    <span *ngIf="result.raw['esatechnicalreportdate']" style="font-size:  .75em; color:  #708b99;">Protocol Completion Date</span>
                </div>






                <div class="box-nis"><!-- regular -->
                    <div class="step" id="summary_slide_deck_{{result.raw['veap_obj_id']}}">
                        <div class="v-stepper">
                          <div class="circle"></div>
                          <!-- <div class="line"></div> -->
                        </div>
                  
            
                        <details id="details_summary_slide_deck_{{result.raw['veap_obj_id']}}" >
                            <summary style="padding:  5px 4px 10px; font-family:  invention; font-size:  13px; cursor:  pointer; font-weight:  400;">Summary Slide Deck</summary>
                            <div *ngFor="let i of convertStringToJson(result.raw['relateddocuments'])">
                                <div *ngIf="i.type__v=='Evidence Synthesis Activities Documents' && (i.subtype__v=='Summary Slide Deck')">
                
                                    <div style="display:  grid;">
                                        <div  style="font-size:  12px;font-weight:  600;padding:  12px; cursor:  pointer; color:  #00857C;" (click)="openNISViewPage(i)">{{i.name__v}}
                                            <span (click)="downloadFile(i)" class="material-icons outlined" style="color:  #00857c; font-size: 15px; line-height: 0; position: relative; top: 4px; left:1rem;" title="Click to download file">download</span>
                                        </div>
                                    </div>

                                    {{nisStepFill('summary_slide_deck_'+result.raw['veap_obj_id'])}}
                                    <!-- {{finalDateFill('finaldate_summary_slide_deck_'+result.raw['veap_obj_id'],result.raw['niscompletiondate']| date :  "MMMM-dd-yyyy")}} -->
                                    <!-- {{finalDateDisclaimerFill('finaldate_disclaimer_summary_slide_deck_'+result.raw['veap_obj_id'])}} -->
                                    <!-- {{nisStepFill('study_protocol_'+result.raw['veap_obj_id'])}} -->
                                    <!-- <div>Type:  {{i.type__v}}</div> -->
                                    <!-- <div>Classification:  Final Study Protocol</div> -->
                
                                </div>
                                
                            </div>
                        </details>
            
                       
                    </div></div>
                <div class="box-nis" style="font-weight:  400;">N/A</div>
                <!-- <div class="box-nis" style="font-weight:  400;">{{result.raw['niscompletiondate'] | date}}</div> -->
                <div class="box-nis" style="font-weight:  400; display:  flex; flex-direction:  column;">
                    <span *ngIf="result.raw['nissummaryslidecompletiondate']">{{result.raw['nissummaryslidecompletiondate'] | date :  "MMMM-dd-yyyy": 'UTC'}}</span>
                    <span  *ngIf="!result.raw['nissummaryslidecompletiondate']">N/A</span>
                    <!-- <span id="finaldate_summary_slide_deck_{{result.raw['veap_obj_id']}}">N/A</span> -->
                    <!-- <span id="finaldate_disclaimer_summary_slide_deck_{{result.raw['veap_obj_id']}}" style="font-size:  .75em; color:  #708b99;"></span> -->
                    <span *ngIf="result.raw['nissummaryslidecompletiondate']" style="font-size:  .75em; color:  #708b99;">Protocol Completion Date</span>
                </div>


            
            
            </div>






            <div style="display:  flex; height:  auto;">
                <div style="width:  50%;">
                    <div *ngIf="result.raw['nisapprovaldate']" class="field" ><span class="field-label">Planned/Actual Protocol Approval Date: </span><span class="field-value">{{result.raw['nisapprovaldate'] | date: 'MMMM-dd-yyyy': 'UTC'}}</span></div>
                    <div *ngIf="!result.raw['nisapprovaldate']" class="field" ><span class="field-label">Planned/Actual Protocol Approval Date: </span><span class="field-value">N/A</span></div>
                    <div *ngIf="result.raw['niscompletiondate']" class="field" ><span class="field-label">Planned/Actual Report Completion Date: </span><span class="field-value">{{result.raw['niscompletiondate'] | date: 'MMMM-dd-yyyy': 'UTC'}}</span></div>
                    <div *ngIf="!result.raw['niscompletiondate']" class="field" ><span class="field-label">Planned/Actual Report Completion Date: </span><span class="field-value">N/A</span></div>
                    <div class="field" *ngIf="result.raw['nisactivitystatus']"><span class="field-label">Activity Status: </span><span class="field-value">{{result.raw['nisactivitystatus']}}</span></div>
                    <div class="field" *ngIf="result.raw['veap_obj_title']"><span class="field-label">REV/OPS ID: </span><span class="field-value">{{result.raw['veap_obj_title']}}</span></div>
                </div>
            </div>


            










    </div>
</div>
















<div style="display:  flex; height:  auto;">
    <div style="width:  50%;">
        <div class="field" *ngIf="result.raw['contentintendeduse'] || (result.raw['contentdocumenttype'] && result.raw['contentdocumenttype'].includes('Listening Priorities'))">
            <span class="field-label">Intended Use: </span>
            <span class="field-value">{{ result.raw['contentintendeduse'] || 'N/A' }}</span>
        </div>
                <div class="field" *ngIf="result.raw['submitter']"><span class="field-label">Submitter: </span><span class="field-value">{{result.raw['submitter']}}</span></div>
        <!-- <div class="field" *ngIf="result.raw['status']"><span class="field-label">Status: </span><span class="field-value">{{result.raw['status']}}</span></div> -->
        <div class="field" *ngIf="result.raw['category']"><span class="field-label">Category: </span><span class="field-value">{{result.raw['category']}}</span></div>
        <div class="more-field" *ngIf="result.raw['event']"><span class="field-label">Event: </span><span class="field-value">{{result.raw['event']}}</span></div>
        <div class="more-field" *ngIf="result.raw['therapeutic_area']"><span class="field-label">Therapeutic Area(s): </span><span class="field-value">{{result.raw['therapeutic_area']}}</span></div>
        <div class="field" *ngIf="result.raw['contentdocumenttype'] && (result.raw['valueevidencesynthesisandcommunication']!='Evidence Synthesis Activities' && result.raw['valueevidencedataandresearch']!='Non-Interventional Studies')"><span class="field-label">Document Type: </span><span class="field-value">{{result.raw['contentdocumenttype']}}</span></div>
        <div class="field" *ngIf="result.raw['contentdocumentsubtype'] && (result.raw['valueevidencesynthesisandcommunication']!='Evidence Synthesis Activities' && result.raw['valueevidencedataandresearch']!='Non-Interventional Studies')"><span class="field-label">Document Subtype: </span><span class="field-value">{{result.raw['contentdocumentsubtype']}}</span></div>
        <div class="field" *ngIf="result.raw['vvpmcontentdocumenttype'] && (result.raw['valueevidencesynthesisandcommunication']!='Evidence Synthesis Activities' && result.raw['valueevidencedataandresearch']!='Non-Interventional Studies')"><span class="field-label">Document Type: </span><span class="field-value">{{result.raw['vvpmcontentdocumenttype']}}</span></div>
        <div class="field" *ngIf="result.raw['vvpmcontentdocumentsubtype'] && (result.raw['valueevidencesynthesisandcommunication']!='Evidence Synthesis Activities' && result.raw['valueevidencedataandresearch']!='Non-Interventional Studies')"><span class="field-label">Document Subtype: </span><span class="field-value">{{result.raw['vvpmcontentdocumentsubtype']}}</span></div>
        <div class="field" *ngIf="result.raw['qddocumenttype'] && result.raw['contentdocumenttype'] && !result.raw['contentdocumenttype'].includes('Listening Priorities')">
            <span class="field-label">Document Type: </span>
            <span class="field-value">{{result.raw['qddocumenttype']}}</span>
          </div>
          <div class="field" *ngIf="result.raw['qddocumentsubtype']"><span class="field-label">Document Subtype: </span><span class="field-value">{{result.raw['qddocumentsubtype']}}</span></div>
          <div class="field" *ngIf="result.raw['gmsavvpmresourcetype'] && result.raw['source'] == 'Veeva Vault PromoMats'"><span class="field-label">Resource Type: </span><span class="field-value">{{result.raw['gmsavvpmresourcetype'] || 'N/A'}}</span></div>
          <div class="field" *ngIf="result.raw['source'] == 'Veeva Vault PromoMats'"><span class="field-label">Therapeutic Area: </span><span class="field-value">{{result.raw['gmsavvpmfranchise'] || 'N/A'}}</span></div>
          <div class="field" *ngIf="result.raw['source'] == 'Veeva Vault PromoMats'"><span class="field-label">Product: </span><span class="field-value">{{result.raw['vvpmcontentproductname']?.[0]}}</span></div>
          <div class="field" *ngIf="result.raw['source'] == 'Veeva Vault PromoMats'"><span class="field-label">Classification: </span><span class="field-value">{{result.raw['gmsavvpmclassification'] || 'N/A'}}</span></div>
          <div class="field" *ngIf="result.raw['contentproactive']"><span class="field-label">Proactive: </span><span class="field-value">{{result.raw['contentproactive']}}</span></div>
        <div class="more-field" *ngIf="result.raw['gmsaapprovaldate'] && result.raw['source'] !== 'Veeva Vault PromoMats'">
            <span class="field-label">Approval Date: </span><span class="field-value">{{getDateFormat(result.raw['gmsaapprovaldate']) | date: 'MMMM-dd-yyyy': 'UTC'}}</span>
        </div>
         <!-- Listening Priorities Starts -->
         <ng-container *ngIf = "result.raw['contentdocumenttype'] && result.raw['contentdocumenttype'].includes('Listening Priorities')" >
            <div class="field" *ngIf="result.raw['contentdocumenttype'] && result.raw['contentdocumenttype'].includes('Listening Priorities')">
                <span class="field-label">Product: </span>
                <span class="field-value">{{result.raw['contentproductname'] || 'N/A'}}</span>
            </div>
            <div class="field" *ngIf="result.raw['contentversion']"><span class="field-label">Version: </span><span class="field-value">{{result.raw['contentversion'] || 'N/A'}}</span></div>
         </ng-container> 
        <!-- Listening Priorities ends -->

        <div class="field" *ngIf="result.raw['payerregion']"><span class="field-label">Payor Region: </span><span class="field-value">{{result.raw['payerregion']}}</span></div>
    </div>

    <div style="width:  50%;">
        
        <div class="field" *ngIf="result.raw['status']"><span class="field-label">Status: </span><span class="field-value">{{result.raw['status']}}</span></div>
        <div class="field" *ngIf="result.raw['submission_date']"><span class="field-label">Submission Date: </span><span class="field-value">{{result.raw['submission_date'] | customdate}}</span></div>
       
        <div class="more-field" *ngIf="result.raw['source'] !== 'Value Evidence Environmental Scan' && result.raw['region']"><span class="field-label">Region(s): </span><span class="field-value">{{result.raw['region']}}</span></div>
      
        <!-- <div class="field" *ngIf="result.raw['submission_date']"><span class="field-label">Status: </span><span class="field-value">{{result.raw['status']}}</span></div> -->
        <div class="field" *ngIf="result.raw['mrlclassification']"><span class="field-label">MRL Classification: </span><span class="field-value">{{result.raw['mrlclassification']}}</span></div>
        <div class="field" *ngIf="result.raw['source']!='Idea Exchange'&& result.raw['contentdocumentnumber'] && !result.raw['valueevidencedataandresearch'] && !result.raw['valueevidencesynthesisandcommunication'] && result.raw['source']!=='Value Evidence Environmental Scan' && result.raw['source'] !== 'Veeva Vault PromoMats'"><span class="field-label">Document Number: </span><span class="field-value">{{result.raw['contentdocumentnumber']}}</span></div>
        <div class="field" *ngIf="result.raw['source']!='Idea Exchange' && result.raw['source']!='GMSA News' && result.raw['contentsourceid'] && !result.raw['valueevidencedataandresearch'] && !result.raw['valueevidencesynthesisandcommunication'] && result.raw['source']!=='Value Evidence Environmental Scan' && result.raw['source'] !== 'Veeva Vault PromoMats'"><span class="field-label">Document ID: </span><span class="field-value">{{result.raw['contentsourceid']}}</span></div>
        <!-- <div class="field" *ngIf="result.raw['steward']"><span class="field-label">Steward / Contact: </span><span class="field-value">{{result.raw['steward'] || 'N/A'}}</span></div> -->
        <div class="field" *ngIf="result.raw['source']!='Idea Exchange' && result.raw['source']!='GMSA News' && result.raw['contentsourceid'] && !result.raw['valueevidencedataandresearch'] && !result.raw['valueevidencesynthesisandcommunication'] && result.raw['source']!=='Value Evidence Environmental Scan' && result.raw['source'] == 'Veeva Vault PromoMats'"><span class="field-label">Steward / Contact: </span><span class="field-value">{{result.raw['steward'] || 'N/A'}}</span></div>
        <div class="field" *ngIf="result.raw['contentlanguage']"><span class="field-label">Language: </span><span class="field-value">{{result.raw['contentlanguage'] || 'N/A'}}</span></div>
        <div class="field" *ngIf="result.raw['vvpmcontentlanguage']"><span class="field-label">Language: </span><span class="field-value">{{result.raw['vvpmcontentlanguage'] || 'N/A'}}</span></div>
        <div class="field" *ngIf="result.raw['gmsacountry']"><span class="field-label">Country: </span><span class="field-value">{{result.raw['gmsacountry'] || 'N/A'}}</span></div>
        <div class="field" *ngIf="result.raw['source']!='Idea Exchange' && result.raw['source']!='GMSA News' && result.raw['contentsourceid'] && !result.raw['valueevidencedataandresearch'] && !result.raw['valueevidencesynthesisandcommunication'] && result.raw['source']!=='Value Evidence Environmental Scan' && result.raw['source'] == 'Veeva Vault PromoMats'"><span class="field-label">Approval for Distribution Date: </span><span class="field-value">{{result.raw['approvaldatedistribution'] | date: 'MMMM-dd-yyyy': 'UTC' || 'N/A'}}</span></div>
        <div class="field" *ngIf="result.raw['contentitemstatus']"><span class="field-label">Status: </span><span class="field-value">{{result.raw['contentitemstatus'] || 'N/A'}}</span></div>
        <!-- <div class="field" *ngIf="result.raw['gmsacountry']"><span class="field-label">Country: </span><span class="field-value">{{result.raw['gmsacountry']}}</span></div> -->
                 <!-- Listening Priorities Starts -->
                 <div class="field" *ngIf="result.raw['contentdocumenttype'] && result.raw['contentdocumenttype'].includes('Listening Priorities')">
                    <span class="field-label">Therapeutic Area: </span>
                    <span class="field-value">{{result.raw['vvmctherapeuticarea'] || 'N/A'}}</span>
                </div>
                <div class="field" *ngIf="result.raw['contentdocumenttype'] && result.raw['contentdocumenttype'].includes('Listening Priorities')">
                    <span class="field-label">Sub Therapeutic Area: </span>
                    <span class="field-value">{{result.raw['gmsa_sub_therapeutic_area'] || 'N/A'}}</span>
                </div>
                <div class="field" *ngIf="result.raw['contentdocumenttype'] && result.raw['contentdocumenttype'].includes('Listening Priorities')">
                    <span class="field-label">Status: </span>
                    <span class="field-value">{{result.raw['contentitemstatus'] || 'N/A'}}</span>
                </div>
                <!-- Listening Priorities ends -->        
        <div style="display:  flex; flex-direction:  row;">
            <div class="field" *ngIf="result.raw['contentinclm'] && !result.raw['contentdocumenttype'].includes('Listening Priorities')"><span class="field-label">Content In CRM: </span><span class="field-value">{{result.raw['contentinclm']}}</span></div>
            <div class="field" *ngIf="result.raw['contentinirms']"><span class="field-label">Content In MI: </span><span class="field-value">{{result.raw['contentinirms']}}</span></div>
        </div>

        <div class="field" *ngIf="result.raw['qdsensitivityclassification']">
            <span class="field-label">Sensitivity Classification: </span><span class="field-value">{{result.raw['qdsensitivityclassification']}}</span>
        </div>
        <div class="field" *ngIf="result.raw['impactedcountry']"><span class="field-label">Impacted Countries/Regions: </span><span class="field-value">{{result.raw['impactedcountry']}}</span></div>
        <div class="field" *ngIf="result.raw['payer']"><span class="field-label">Payor Use Only: </span><span class="field-value">{{result.raw['payer']}}</span></div>
        <div class="field" *ngIf="result.raw['payertopic']"><span class="field-label">Payor Topic: </span><span class="field-value">{{result.raw['payertopic']}}</span></div>
    </div>
</div>
                    <div *ngIf="!result.raw['valueevidencedataandresearch'] && !result.raw['valueevidencesynthesisandcommunication'] && result.raw['source'] !== 'Veeva Vault PromoMats'">
                        <details *ngIf="!result.raw['submitter'] && !(result.raw['contentdocumenttype'] && result.raw['contentdocumenttype'].includes('Listening Priorities'))">
                            <summary *ngIf="!result.raw['submitter']" style="color:  teal; padding:  0px 4px 10px; font-family:  invention; font-size:  13px; cursor:  pointer;">More Details</summary>
                            <div style="display:  flex; height:  auto; padding:  0rem 1rem 1rem;">
                                <div style="width:  100%;">

                                    <!-- <div class="more-field" *ngIf="result.raw['event']"><span class="field-label">Event: </span><span class="field-value">{{result.raw['event']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['region']"><span class="field-label">Region: </span><span class="field-value">{{result.raw['region']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['therapeutic_area']"><span class="field-label">Therapeutic Area(s): </span><span class="field-value">{{result.raw['therapeutic_area']}}</span></div> -->
                                    <div class="more-field" *ngIf="result.raw['gmsaregion']"><span class="field-label">Region: </span><span class="field-value">{{result.raw['gmsaregion']}}</span></div>
                                    <!-- <div class="more-field" *ngIf="result.raw['spouniqueid']"><span class="field-label">UniqueId: </span><span class="field-value">{{result.raw['spouniqueid']}}</span></div> -->
                                    <div class="more-field" *ngIf="result.raw['contentproductname']"><span class="field-label">Product: </span><span class="field-value">{{result.raw['contentproductname']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['vvmctherapeuticareaveap']">
                                        <span class="field-label">Therapeutic Area (VEAP): </span><span class="field-value">{{result.raw['vvmctherapeuticareaveap']}}</span>
                                    </div>
                                    <div class="more-field" *ngIf="result.raw['vvmctherapeuticarea']"><span class="field-label">Therapeutic Area: </span><span class="field-value">{{result.raw['vvmctherapeuticarea']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['contentlanguage']"><span class="field-label">Language: </span><span class="field-value">{{result.raw['contentlanguage']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['contentdocumentowner'] && result.raw['source'] == 'Veeva Vault MedComms'"><span class="field-label">Document Owner: </span><span class="field-value">{{result.raw['contentdocumentowner']}}</span></div>
                                    <!-- <div class="more-field" *ngIf="result.raw['vvmcallowpdfdownload']"><span class="field-label">Allow PDF Download: </span><span class="field-value">{{result.raw['vvmcallowpdfdownload']}}</span></div> -->

                                    <div class="more-field" *ngIf="result.raw['contentspcategoryannouncements']">
                                        <span class="field-label">Announcements: </span><span class="field-value">{{result.raw['contentspcategoryannouncements']}}</span>
                                    </div>
                                    <div class="more-field" *ngIf="result.raw['contentspcategoryfunctionalareas']">
                                        <span class="field-label">Functional Areas: </span><span class="field-value">{{result.raw['contentspcategoryfunctionalareas']}}</span>
                                    </div>
                                    <div class="more-field" *ngIf="result.raw['contentspcategorygmacapabilities']">
                                        <span class="field-label">GMA Capabilities: </span><span class="field-value">{{result.raw['contentspcategorygmacapabilities']}}</span>
                                    </div>
                                    <div class="more-field" *ngIf="result.raw['contentspcategoryleadermessages']">
                                        <span class="field-label">Leader Messages: </span><span class="field-value">{{result.raw['contentspcategoryleadermessages']}}</span>
                                    </div>
                                    <div class="more-field" *ngIf="result.raw['contentspcategoryregions']"><span class="field-label">Regions: </span><span class="field-value">{{result.raw['contentspcategoryregions']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['contentspcategoryspecialtopics']">
                                        <span class="field-label">Special Topics: </span><span class="field-value">{{result.raw['contentspcategoryspecialtopics']}}</span>
                                    </div>
                                    <div class="more-field" *ngIf="result.raw['contentspcategorytherapeuticareas']">
                                        <span class="field-label">Therapeutic Areas: </span><span class="field-value">{{result.raw['contentspcategorytherapeuticareas']}}</span>
                                    </div>
                                    <div class="more-field" *ngIf="result.raw['contenttherapeuticarea']"><span class="field-label">Therapeutic Areas: </span><span class="field-value">{{result.raw['contenttherapeuticarea']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['contentspauthor']"><span class="field-label">Author: </span><span class="field-value">{{result.raw['contentspauthor']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['gmsapublisheddate']">
                                        <span class="field-label">Published Date: </span><span class="field-value">{{getDateFormat(result.raw['gmsapublisheddate'])| date: 'MMMM-dd-yyyy': 'UTC'}}</span>
                                    </div>
                                    <div class="more-field" *ngIf="result.raw['contentversion'] && result.raw['contentdocumenttype'] && !result.raw['contentdocumenttype'].includes('Listening Priorities')">
                                        <span class="field-label">Version: </span>
                                        <span class="field-value">{{result.raw['contentversion'] || 'N/A'}}</span>
                                      </div> 
                                    <div class="more-field" *ngIf="result.raw['qdimpacteddepartment']"><span class="field-label">Impacted Department: </span><span class="field-value">{{result.raw['qdimpacteddepartment']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['contentitemstatus']"><span class="field-label">Status: </span><span class="field-value">{{result.raw['contentitemstatus']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['qdeffectivedate']">
                                        <span class="field-label">Effective Date: </span><span class="field-value">{{getDateFormat(result.raw['qdeffectivedate']) | date: 'MMMM-dd-yyyy': 'UTC'}}</span>
                                    </div>
                                    <!-- <div class="more-field" *ngIf="result.raw['qdfinaldate']"><span class="field-label">Final Date: </span><span class="field-value">{{getDateFormat(result.raw['qdfinaldate']) | date: MMMM-dd-yyyy: 'UTC'}}</span></div> -->


                                    <div class="more-field" *ngIf="result.raw['qdfinaldate']"><span class="field-label">Final Date: </span><span class="field-value">{{result.raw['qdfinaldate'] | date: 'MMMM-dd-yyyy': 'UTC'}}</span></div>

                                    <div class="more-field" *ngIf="result.raw['gvdsection']"><span class="field-label">GVD Section: </span><span class="field-value">{{result.raw['gvdsection']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['vvmcgvdeffectivedate']">
                                        <span class="field-label">Effective Date: </span><span class="field-value">{{getDateFormat(result.raw['vvmcgvdeffectivedate']) | date: 'MMMM-dd-yyyy': 'UTC'}}</span>
                                    </div>
                                    <div class="more-field" *ngIf="result.raw['qddocumentversionowner']"><span class="field-label">Version Owner: </span><span class="field-value">{{result.raw['qddocumentversionowner']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['vvmcgvdindication']"><span class="field-label">Indication: </span><span class="field-value">{{result.raw['vvmcgvdindication']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['vvmcgvdveapid'] && result.raw['contentdocumentsubtype']!='AMCP Dossier'"><span class="field-label">VEAP ID OR REV/OPS ID: </span><span class="field-value">{{result.raw['vvmcgvdveapid']}}</span></div>

                                    <div class="more-field" *ngIf="result.raw['vvmcgvdveapid'] && result.raw['contentdocumentsubtype']=='AMCP Dossier'"><span class="field-label">REV/OPS ID: </span><span class="field-value">{{result.raw['vvmcgvdveapid']}}</span></div>

                                    <div class="more-field" *ngIf="result.raw['productgenerictradename']">
                                        <span class="field-label">Product Generic/Trade Name: </span><span class="field-value">{{result.raw['productgenerictradename']}}</span>
                                    </div>
                                    <div class="more-field" *ngIf="result.raw['vvmcgvdkeycontact']"><span class="field-label">Key Contact: </span><span class="field-value">{{result.raw['vvmcgvdkeycontact']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['vvmcgvddepartment']"><span class="field-label">Department (VEAP): </span><span class="field-value">{{result.raw['vvmcgvddepartment']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['vvmc_core_model_type']"><span class="field-label">Model Type: </span><span class="field-value">{{result.raw['vvmc_core_model_type']}}</span></div>
                                    <div class="more-field" *ngIf="result.raw['vvmc_core_dcr_approval_date']">
                                        <span class="field-label">DRC Approval Date: </span><span class="field-value">{{getDateFormat(result.raw['vvmc_core_dcr_approval_date']) | date: 'MMMM-dd-yyyy': 'UTC'}}</span>
                                    </div>
                                    <div class="more-field" *ngIf="result.raw['vvmc_core_activity_type']"><span class="field-label">Activity Type: </span><span class="field-value">{{result.raw['vvmc_core_activity_type']}}</span></div>
                                    <!-- <div class="more-field" *ngIf="result.raw['qddocumentversionowner']"><span class="field-label">Version Owner: </span><span class="field-value">{{result.raw['qddocumentversionowner']}}</span></div> -->
                                </div>
                            </div>
                                                <!-- VEES Details -->

                            <div *ngIf="result.raw['source']=='Value Evidence Environmental Scan'"  id="vees" >
                                    <div class="veesMoreDetails" style="display:  flex; height:  auto; padding:  0rem 1rem 1rem;">
                                        <div style="width:  100%;">
                                        <div class="more-field">
                                            <span class="field-label">Edition Date: </span>
                                            <span class="field-value">{{ formatDate(result.raw['vees_edition'] ? result.raw['vees_edition'] : 'N/A') }}</span>
                                        </div>
                                        <div class="more-field">
                                            <span class="field-label">Region: </span>
                                            <span class="field-value">{{ result.raw['vees_region'] ? result.raw['vees_region'] :  'N/A' }}</span>
                                        </div>
                                        <div class="more-field">
                                            <span class="field-label">Merck Disease Area: </span>
                                            <span class="field-value">{{ result.raw['vees_merck_disease_area'] ? result.raw['vees_merck_disease_area'] :  'N/A' }}</span>
                                        </div>
                                        <div class="more-field">
                                            <span class="field-label">Source: </span>
                                            <span class="field-value">
                                                <ng-container *ngIf="result.raw['vees_source'] !== 'GenericRest' && result.raw['syssourcetype'] !== ''">
                                                    <a href="javascript:void(0);" (click)="openLinkURL(result)" rel="noreferrer noopener">
                                                        {{ result.raw['vees_source'] }}
                                                    </a>
                                                </ng-container>
                                                <ng-container *ngIf="!result.raw['vees_source']">
                                                   N/A
                                                </ng-container>
                                            </span>
                                        </div>                                                                                                                  
                                    </div>
                                    </div>
                             </div>  
                        </details>
                                                                        <!-- Listening Priorities Starts -->

                        <!-- <div *ngIf="result.raw['description'] || (result.raw['contentdocumenttype'] && result.raw['contentdocumenttype'].includes('Listening Priorities'))" style="display:  flex; flex-direction:  column; gap:  6px; font-family:  invention; padding-bottom:  1rem; padding-top:  1rem; border-top:  1px solid rgba(235, 235, 235, 1);"> -->
                        <div *ngIf="result.raw['description'] || result.raw['contentdescription']" style="display:  flex; flex-direction:  column; gap:  6px; font-family:  invention; padding-bottom:  1rem; padding-top:  1rem; border-top:  1px solid rgba(235, 235, 235, 1);">
                            <div style="font-weight:  700; font-size:  14px;">Description</div>
                            <div style="font-weight:  400; font-family:  invention; word-break: break-word;"> 
                                <span *ngIf="removeHTMLContent(result.raw['description']) > 400">
                                    <span *ngIf="!isExpanded">
                                        {{ (result.raw['description'] | removehtml | slice: 0:400) || (result.raw['contentdescription'] | removehtml | slice: 0:400) || 'N/A' }}...
                                    </span>
                                                                        <span *ngIf="isExpanded"> {{result.raw['description'] | removehtml}}</span>
                                    <a style="color: teal" href="javascript: void(0)" (click)="toggleReadMore()">
                                        {{isExpanded ? 'Read Less':  'Read More'}}
                                    </a>
                                </span>
                                <span *ngIf="removeHTMLContent(result.raw['description']) <= 400">
                                    <span>
                                        {{ (result.raw['description'] | removehtml | slice: 0:400) || (result.raw['contentdescription'] | removehtml | slice: 0:400) || 'N/A' }}
                                    </span>
                                </span>
                            </div>
                        </div>
                        <div *ngIf="(result.raw['source'] === 'Value Evidence Environmental Scan' && !result.raw['description']) || (result.raw['contentdocumenttype'] && result.raw['contentdocumenttype'].includes('Listening Priorities') && !result.raw['contentdescription'])" style="display:  flex; flex-direction:  column; gap:  6px; font-family:  invention; padding-bottom:  1rem; padding-top:  1rem; border-top:  1px solid rgba(235, 235, 235, 1);">
                            <div style="font-weight:  700; font-size:  14px;">Description:  <span style="font-weight:  400;">N/A</span></div>
                        </div>                                               
                        <div class="item-bottom" style="margin-top:  auto;"></div>
                    </div>
                </div>
                </div>
                      <!-- Listening Priorities Ends -->

            <div style="margin-left:  -20px; margin-right:  52px; display:  flex; justify-content:  space-between; background:  white; width:  100%; height:  51px; align-items:  center; padding:  0px 20px 0px; border-top:  1px solid #ebebeb;">
                <span class="buttonsStyle" *ngIf="result.raw['valueevidencesynthesisandcommunication']!='Evidence Synthesis Activities' && result.raw['valueevidencedataandresearch']!='Non-Interventional Studies'&& result.raw['source']!='Idea Exchange' && result.raw['source']!='Value Evidence Environmental Scan' && result.raw['source'] !== 'Veeva Vault PromoMats'">
                    <div style="display:  flex;">
                        <div title="Click to preview"><app-quick-view [imageUrl]="imageUrl" [result]="result"></app-quick-view></div>
                    </div>
                </span>
                <!-- <span *ngIf="result.raw['source']!='Idea Exchange' && result.raw['valueevidencesynthesisandcommunication']!='Evidence Synthesis Activities' && result.raw['valueevidencedataandresearch']!='Non-Interventional Studies'&& result.raw['source']!=='VEES'" class="vertical-border"></span>
                <span *ngIf="result.raw['source']=='Idea Exchange' && isRockyMountainUser == false && result.raw['valueevidencesynthesisandcommunication']!='Evidence Synthesis Activities' && result.raw['valueevidencedataandresearch']!='Non-Interventional Studies' && (result.raw['syssource']==='VEES' && result.raw['sourcetype']!=='APM Health - Contact the steward for original article')" class="buttonsStyle"> -->

                    <span *ngIf="result.raw['valueevidencesynthesisandcommunication']!='Evidence Synthesis Activities' && result.raw['valueevidencedataandresearch']!='Non-Interventional Studies'&& result.raw['source']!= 'Value Evidence Environmental Scan' && result.raw['source']!='Idea Exchange' && result.raw['source'] !== 'Veeva Vault PromoMats'" class="vertical-border"></span>
                <span *ngIf="isRockyMountainUser == false && result.raw['valueevidencesynthesisandcommunication']!='Evidence Synthesis Activities' && result.raw['valueevidencedataandresearch']!='Non-Interventional Studies'&& result.raw['source']!= 'Value Evidence Environmental Scan'" class="buttonsStyle">
                    <span class="botton-action-icons">
                        <a style="text-decoration:  none; color:  black;" (click)="openLink(result,i)">
                            <!-- <img src="../../assets/merck-assets/OpeninSource.png" /> -->

                            <span class="material-icons outlined" style="color:  #00857c;" title="Click to open in source">
                                open_in_new
                            </span>
                        </a>
<!-- >>>>>>> main -->
                    </span>
                    <span class="botton-action-labels" title="Click to open in source"> <a (click)="openLink(result,i)" style="text-decoration:  none; color:  black;">Open in Source</a></span>
                </span>
                <!-- <span *ngIf="result.raw['source']=='Idea Exchange'" class="buttonsStyle">
                    <span class="botton-action-icons">
                        <a style="text-decoration:  none; color:  black;" (click)="openLink(result);customEventsOpenInSource(result)">
                           

                            <span class="material-icons outlined" style="color:  #00857c;" title="Click to open in source">
                                open_in_new
                            </span>
                        </a>

                    </span>
                    <span class="botton-action-labels" title="Click to open in source"> <a (click)="openLink(result);customEventsOpenInSource(result)" style="text-decoration:  none; color:  black;">Open in Source</a></span>
                </span> -->


                <div *ngIf="filedownloaderror" class="overlay">
                    <div class="popup">
                        <div class="content">
                            <div class="title">
                                <!-- Error! -->
                            </div>
                            <div style="display:  flex; flex-direction:  column;">
                                <div class="input-section">
                                    Download can't happen due to the file type.                         </div>
                                <div class="btn-section">
                                    <button (click)="filedownloaderror=false;" class="docFavSaveBtn">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <span *ngIf="result.raw['valueevidencesynthesisandcommunication']!='Evidence Synthesis Activities' && result.raw['valueevidencedataandresearch']!='Non-Interventional Studies' && result.raw['source'] !== 'Value Evidence Environmental Scan'" class="vertical-border"></span>
                <div *ngIf="maxSelectionPopUp" class="overlay" (click)="copyLinkFlag = false">
                    <div class="popup">
                        <div class="content">
                            <div class="title">
                                {{selectionExceedTitle}}
                            </div>
                            <div style="display:  flex; flex-direction:  column;">
                                <div class="input-section">
                                  {{selectionExceedBody}}                           </div>
                                <div class="btn-section">
                                    <button (click)="closeMaxPopUp()" class="docFavSaveBtn">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                                    <!-- VEES Details popup -->

                    <div *ngIf="veesNoSourceFlag" class="overlay" (click)="veesNoSourceFlag = false">
                        <div class="popup">
                            <div class="content">
                                <div class="title">
                                    VALUE EVIDENCE ENVIRONMENTAL SCAN
                                </div>
                                <div style="display:  flex; flex-direction:  column;">
                                    <div class="input-section">
                                        <span *ngIf="!veesBlankSourceFlag">
                                            Multiple [APM Health (Contact the steward for further information) and Others]
                                        </span>                                      
                                    </div>
                                    <div class="btn-section">
                                        <button (click)="veesNoSourceFlag=false" class="docFavSaveBtn">okay</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <div *ngIf="selectionFreeze" class="overlay" (click)="copyLinkFlag = false">
                    <div class="popup">
                        <div class="content">
                            <div class="title">
                             {{selectionFreezeTitle}}
                            </div>
                            <div style="display:  flex; flex-direction:  column;">
                                <div class="input-section">
            {{selectionFreezeBody}}                           </div>
                                <div class="btn-section">
                                    <button (click)="closeSelectionPopUp()" class="docFavSaveBtn">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span *ngIf="isRockyMountainUser == false" class="buttonsStyle" title="Click to copy the URL">
                    <span (click)="copylink(result);customEventCopyLink(result)" class="botton-action-icons" style="color:  #00857c;">
                        <!-- <img src="../../assets/merck-assets/copylink.png" /> -->
                        <span class="material-icons outlined">
                            link
                        </span>
                    </span>
                    <span (click)="copylink(result);customEventCopyLink(result)" class="botton-action-labels">Copy Link</span>
                    <div *ngIf="snackBarFlag" class="snackBar">Link is Copied</div>
                    <ng-container>
                        <div *ngIf="copyLinkFlag" class="overlay" (click)="copyLinkFlag = false">
                            <div class="popup">
                                <div class="content">
                                    <div class="title">
                                        Copy Link for
                                        <span *ngIf="copyLinkName.length < 40">
                                            {{copyLinkName}}
                                        </span>
                                        <span *ngIf="copyLinkName.length >= 40">
                                            {{copyLinkName}}...
                                        </span>
                                    </div>
                                    <div style="display:  flex; flex-direction:  column;">
                                        <div class="input-section">
                                            <input disabled class="inputCopyLink" type="text" [value]="copyText" />
                                        </div>
                                        <div class="btn-section">
                                            <button (click)="copyLinkFlag=false" class="docFavSaveBtn">Copy Link</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </span>
                <span class="vertical-border"></span>
                <span *ngIf="isRockyMountainUser == false" class="buttonsStyle" title="Click to share" (click)="shareItem(result);customEventsShareItem(result)">
                    <span class="botton-action-icons" style="color:  #00857c;">
                        <span class="material-icons outlined" title="Click to share">
                            share
                        </span>

                        <!-- <img src="../../assets/merck-assets/Share_Icon_Fill.png" /> -->
                    </span>
                    <span class="botton-action-labels">Share</span>
                </span>
                <span *ngIf="result.raw['source']!='Idea Exchange'&& result.raw['source']!=='Value Evidence Environmental Scan' && result.raw['source'] !== 'Veeva Vault PromoMats'" class="vertical-border"></span>
                <span *ngIf="isRockyMountainUser == false && result.raw.source!='Idea Exchange'&& result.raw.source!='GMSA News' && result.raw.source!='Value Evidence Environmental Scan' && (result.raw['valueevidencesynthesisandcommunication']!='Evidence Synthesis Activities' && result.raw['valueevidencedataandresearch']!='Non-Interventional Studies') && result.raw['source'] !== 'Veeva Vault PromoMats'">

                    <app-onedrive [oneDriveDocs]="oneDriveDocs" [result]="result" [selectedDocs]="selectedDocs"></app-onedrive>
                </span>
                <span *ngIf="result.raw.source!='GMSA News' && (result.raw['valueevidencesynthesisandcommunication']!='Evidence Synthesis Activities' && result.raw['valueevidencedataandresearch']!='Non-Interventional Studies')" class="vertical-border" style="height:  29px; width:  1px; background:  #ebebeb;"></span>
                <span *ngIf="isRockyMountainUser == false">
                    <span (click)="addTocollections(result,'remove');customEventRemovetoCollections(result,'remove')" *ngIf="checkCollectionStatus(result).hasCollection;else unfilled" class="buttonsStyle">
                        <!-- <span (click)="addTocollections(result,'remove');customEventRemovetoCollections(result,'remove')" *ngIf="checkCollectionStatus(result);else unfilled" class="buttonsStyle"> -->
                        <!-- <span class="botton-action-icons" style="margin-right:  -3rem;"> -->
                        <span class="botton-action-icons"  style="color:  #00857c;">
                            <span class="material-icons outlined" title="Click to add/remove from collection">
                                bookmark
                            </span>
                            <!-- <img style="width:  26%; margin-right:  5px;" src="{{collection_icon_filled}}" /> -->
                        </span>
                        <!-- <span class="botton-action-labels" title="Click to add/remove from collection">Add/Remove from Collection</span> -->
                        <span class="botton-action-labels" title="Click to add/remove from collection">In {{checkCollectionStatus(result).docPartOfHowManyCollections}} Collection(s)</span>
                    </span>
                    <ng-template #unfilled>
                        <span (click)="addTocollections(result,'add');customEventAddtoCollections(result,'add')" class="buttonsStyle">
                            <!-- <span class="botton-action-icons" style="margin-right:  -3rem;"> -->
                            <span class="botton-action-icons"  style="color:  #00857c;">
                                <!-- <img style="width:  26%; margin-right:  5px;" src="{{collection_icon_unfilled}}" /> -->

                                <span class="material-icons outlined" title="Click to add/remove from collection">
                                    bookmark_border
                                </span>
                            </span>
                            <span class="botton-action-labels" title="Click to add/remove from collection">Add/Remove from Collection</span>
                        </span>
                    </ng-template>
                    <app-collections #collectionsComp [allCollections]="collectionslist" [selectedDocs]="selectedDocs" [document]="result" [result]="result"></app-collections>
                    <span class="vertical-border"></span>
                </span>
            </div>
        </div>
    </div>


